<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <button v-show="!showBackToSurveyBtn" type="submit" v-on:click.prevent="backToSurvey" class="btn btn-primary waves-effect pull-right">Back to Survey</button>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <div class="card-body card-body-padding-none">
                    <!-- Forms -->
                    <div class="forms-container">
                        <div class="no-margin" style="font-size: 25px; text-align: center;">
                            {{ customEndMsg }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    var self;
    export default {
        name: "SurveySuccess",
        data() {
            return {
                customEndMsg: '',
                showBackToSurveyBtn: 1,
            }
        },
        beforeMount: function() {
            self = this;
        },
        mounted: function() {
            self = this;
            const storedSurveyData = JSON.parse(localStorage.getItem('survey_data'));

            self.customEndMsg = storedSurveyData.custom_end_message;
            self.showBackToSurveyBtn = storedSurveyData.show_back_to_survey_btn;
        },
        methods: {
            backToSurvey: function() {
                localStorage.removeItem('survey_data');
                self.$router.push("/");
            },
        },
    }
</script>