<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div v-if="$route.params.id > 0" class="page-name">Edit Question</div>
                <div v-else class="page-name">Add Question</div>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <div class="card-body card-body-padding-none">
                    <!-- Forms -->
                    <div class="forms-container">
                        <div class="row no-margin">
                            <b-form id="question_add_update" class="CustomChangeProfile">
                                <template>
                                    <!-- 1st Row -->
                                    <div class="col-12 forms-block">
                                        <div class="col-2 paddingleft0 QuizLeft">
                                            <div class="form-label">Select Survey :</div>
                                        </div>
                                        <div class="col-10 paddingleft0 QuizLeft">
                                            <v-select
                                                    v-model="selectedSurvey"
                                                    name="survey"
                                                    placeholder="Select Survey"
                                                    id="selectedSurvey"
                                                    @input="onChangeSurvey()"
                                                    :options="surveyOptions"
                                                    label="name"
                                                    :disabled="!isEditingAllowed"
                                            >
                                                <template slot="no-options">Type Survey here</template>
                                                <template slot="option" slot-scope="option">
                                                    <div class="d-center">{{ option.name }}</div>
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">{{ option.name }}</div>
                                                </template>
                                            </v-select>
                                            <div class="requiredstar">*</div>
                                            <font class="text-danger" v-if="surveyError.length > 0">{{ surveyError }}</font>
                                        </div>
                                    </div>
                                    <!-- 1st Row -->

                                    <!-- 2nd Row -->
                                    <div class="col-12 forms-block">
                                        <div class="col-2 paddingleft0 QuizLeft">
                                            <div class="form-label">Question:</div>
                                        </div>
                                        <div class="col-10 paddingleft0 QuizLeft">
                                            <b-form-textarea
                                                    id="question"
                                                    v-model="questionObject.question"
                                                    placeholder="Enter Question..."
                                                    class="form-control"
                                                    rows="3"
                                                    max-rows="6"
                                                    :disabled="!isEditingAllowed"
                                            ></b-form-textarea>
                                            <div class="requiredstar">*</div>
                                            <font class="text-danger" v-if="questionError.length > 0">{{ questionError }}</font>
                                        </div>
                                    </div>
                                    <!-- 2nd Row -->

                                    <!-- 3rd Row -->
                                    <div class="col-12 forms-block" v-show="false">
                                        <div class="col-2 paddingleft0 QuizLeft">
                                            <div class="form-label">Section:</div>
                                        </div>
                                        <div class="col-10 paddingleft0 QuizLeft">
                                            <b-form-radio-group
                                                    id="sectiongroup"
                                                    v-model="questionObject.section"
                                                    :options="section"
                                                    name="section-options"
                                                    :disabled="!isEditingAllowed"
                                            ></b-form-radio-group>
                                            <font class="text-danger" v-if="sectionError.length > 0">{{ sectionError }}</font>
                                        </div>
                                    </div>

                                    <div class="col-12 forms-block">
                                        <div class="col-2 paddingleft0 QuizLeft">
                                            <div class="form-label">Question Type:</div>
                                        </div>
                                        <div class="col-10 paddingleft0 QuizLeft">
                                            <b-form-radio-group
                                                    id="questiontypegroup"
                                                    v-model="questionObject.question_type"
                                                    :options="question_type"
                                                    name="question-type-options"
                                                    :disabled="!isEditingAllowed"
                                            ></b-form-radio-group>
                                            <font class="text-danger" v-if="questionTypeError.length > 0">{{ questionTypeError }}</font>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <!-- 3rd Row -->

                                    <!-- 4th Row -->
                                    <div class="col-12 forms-block">
                                        <div class="col-2 paddingleft0 QuizLeft">
                                            <div class="form-label">Question Images:</div>
                                        </div>
                                        <div class="col-10 paddingleft0 QuizLeft">
                                            <vue-dropzone
                                                    ref="dropzone"
                                                    id="dropzone"
                                                    :options="dropzoneOptions"
                                                    @vdropzone-success="uploadDocument"
                                                    @vdropzone-removed-file="removedSelectedFile"
                                                    @vdropzone-error="fileError"
                                                    @vdropzone-file-added="fileAdd"
                                                    @vdropzone-total-upload-progress="progressOfUload"
                                                    :useCustomSlot="true"
                                                    :disabled="!isEditingAllowed"
                                            >
                                                <div class="dropzone-custom-content">
                                                    <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                                    <div class="subtitle">(Select files from your computer.)</div>
                                                </div>
                                            </vue-dropzone>
                                        </div>
                                    </div>
                                    <!-- 4th Row -->

                                    <!-- <template v-if="!isHideAnswerBlock"> -->
                                    <!-- 5th Row -->
                                    <template v-if="!isHideMandatoryBlock">
                                        <div class="col-12 forms-block isRequiredBlock">
                                            <div class="col-2 paddingleft0 QuizLeft">
                                                <div class="form-label">Is Mandatory:</div>
                                            </div>
                                            <div class="col-10 paddingleft0 QuizLeft">
                                                <b-form-checkbox
                                                        id="checkbox-1"
                                                        v-model="questionObject.is_required"
                                                        name="question-is-required"
                                                        value="1"
                                                        unchecked-value="0"
                                                        :disabled="!isEditingAllowed"
                                                ></b-form-checkbox>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- 5th Row -->
                                    <template v-if="!isHideAnswerBlock">
                                        <!-- 6th Row -->
                                        <div class="col-12 forms-block EnterAnswerBlock" v-for="(ansOption,index) in questionObject.answerOptions" v-bind:key="index">
                                            <div class="col-9 paddingleft0 float-left">
                                                <b-form-input
                                                        v-model="ansOption.answeTxt"
                                                        ref="ansInputRef"
                                                        placeholder="Enter your answer"
                                                        @change="changeAnswerInput(ansOption)"
                                                ></b-form-input>
                                            </div>
                                            <div class="col-2 paddingleft0 float-left">
                                                <!-- <b-form-checkbox
                                                    v-model="ansOption.is_required"
                                                    name
                                                    value="1"
                                                    unchecked-value="0"
                                                    :disabled="!isEditingAllowed"
                                                >Is Right Answer?</b-form-checkbox> -->
                                            </div>
                                            <div class="col-1 paddingleft0 float-left">
                                                <span></span>
                                                <div v-if="questionObject.other_option == 1">
                                                    <b-button
                                                            v-show="index != questionObject.answerOptions.length -1"
                                                            size="lg"
                                                            variant="primary"
                                                            @click="removeAnswer(index, ansOption)"
                                                            :disabled="!isEditingAllowed"
                                                    ><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </b-button>
                                                </div>
                                                <div v-else>
                                                    <b-button
                                                            size="lg"
                                                            variant="primary"
                                                            @click="removeAnswer(index, ansOption)"
                                                            :disabled="!isEditingAllowed"
                                                    ><i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    </b-button>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-12 forms-block isRequiredBlock">
                                            <div class="col-2 paddingleft0 QuizLeft">
                                                <div class="form-label">Add Other Option:</div>
                                            </div>
                                            <div class="col-10 paddingleft0 QuizLeft">
                                                <b-form-checkbox
                                                        id="other_option"
                                                        v-model="questionObject.other_option"
                                                        name="other_option"
                                                        value="1"
                                                        unchecked-value="0"
                                                        @change="changeOtherOption()"
                                                ></b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-12 forms-block QuizButton">
                                            <div class="col-9 paddingleft3 paddingnone">
                                                <b-button
                                                        size="lg"
                                                        variant="primary"
                                                        @click="addAnswer('add_answer')"
                                                        :disabled="!isEditingAllowed"
                                                >Add Answer</b-button>
                                            </div>
                                        </div>
                                        <!-- 6th Row -->
                                    </template>

                                    <!-- 7th Row -->
                                    <template v-if="isHideMandatoryBlock">
                                        <div class="col-12 forms-block">
                                            <div class="col-2 paddingleft0 QuizLeft">
                                                <div class="form-label">Question Note:</div>
                                            </div>
                                            <div class="col-10 paddingleft0 QuizLeft">
                                                <b-form-textarea
                                                        id="questionNote"
                                                        v-model="questionObject.question_note"
                                                        placeholder="Enter Question Note..."
                                                        class="form-control"
                                                        rows="3"
                                                        max-rows="6"
                                                        :disabled="!isEditingAllowed"
                                                ></b-form-textarea>
                                                <div class="requiredstar">*</div>
                                                <font class="text-danger" v-if="questionNoteError.length > 0">{{ questionNoteError }}</font>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- 7th Row -->

                                    <!--Position-->
                                    <!--<div class="col-12 forms-block">
                                        <div class="col-2 paddingleft0 QuizLeft">
                                            <div class="form-label">Position :</div>
                                        </div>
                                        <div class="col-3 paddingleft0 QuizLeft">
                                            <b-form-input
                                                    id="questionPosition"
                                                    v-model="questionPosition"
                                                    ref="questionPositionRef"
                                                    :placeholder="placeholderPosition"
                                                    @input="changeQuestionPositionInput()"
                                                    @change="changeQuestionPosition()"
                                            ></b-form-input>
                                        </div>
                                        <span>{{postionSpanText}}</span>
                                        <div class="clearfix"></div>
                                        <span class="text-danger">{{questionPositionError}}</span>
                                    </div>-->

                                    <div class="col-12 forms-block">
                                        <div class="button-box QuizButton outline-button bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20" style="box-shadow:none;">
                                            <b-button
                                                    size="lg"
                                                    variant="primary"
                                                    :disabled="isFileUploading"
                                                    @click="addQuestion(false)"
                                                    ref="btnquestion"
                                            >
                                                <template v-if="$route.params.id > 0">Update</template>
                                                <template v-else>Save</template>
                                            </b-button>
                                        </div>
                                        <div v-if="$route.params.id == null" class="button-box QuizButton outline-button bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20" style="box-shadow:none;">
                                            <b-button
                                                    size="lg"
                                                    variant="primary"
                                                    :disabled="isFileUploading"
                                                    @click="addQuestion(true)"
                                                    ref="btnquestion"
                                            >Save and Continue</b-button>
                                        </div>
                                        <div class="button-box QuizButton outline-button bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20">
                                            <b-button
                                                    size="lg"
                                                    variant="secondary"
                                                    :disabled="isFileUploading"
                                                    @click="buttonBack"
                                            >Cancel</b-button>
                                        </div>
                                    </div>

                                </template>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <backend-footer></backend-footer>
    </div>
    <!-- /#page-content-wrapper -->
</template>

<script>
    import { HTTP } from "../../../http-common";
    import BackendFooter from "@/components/backend/partials/footer";
    import { commonMixins } from "../../../mixins/commonMixins";
    import vSelect from "vue-select";
    
    /** Vue2 Dropzone */
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import EventBus from "../../event-bus";

    var self;
    var qDropZone;
    var filesLeft = 4;

    export default {
        name: "AddQuestion",
        components: {
            "v-select": vSelect,
            "backend-footer": BackendFooter,
            vueDropzone: vue2Dropzone,
        },
        mixins: [commonMixins],
        data() {
            return {
                text: "",
                pageTitle: "Add Question",
                questionObject: {
                    question: "",
                    comment: "",
                    survey_id: "",
                    section: "user",
                    is_required: 0,
                    question_type: "single",
                    question_note: "",
                    answerOptions: [],
                    other_option : 0
                },
                questionError: "",
                questionNoteError: "",
                surveyError: "",
                sectionError: "",
                questionTypeError: "",
                surveyOptions: [],
                questionPosition : 0,
                maxPosition : 0,
                questionPositionError : "",
                postionSpanText : "",
                placeholderPosition : "Enter Position",
                selectedSurvey: {
                    id: 0,
                    name: ""
                },
                section: [
                    {
                        value: "user",
                        text: "User"
                    },
                    {
                        value: "survey",
                        text: "Survey"
                    }
                ],
                question_type: [
                    {
                        value: "single",
                        text: "Single"
                    },
                    {
                        value: "multiple",
                        text: "Multiple"
                    },
                    {
                        value: "free",
                        text: "Free Text"
                    },
                    {
                        value: "note",
                        text: "Note"
                    },
                    {
                        value: "attachment",
                        text: "Attachment"
                    },
                ],
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/question/uploadimage",
                    method: "POST",
                    paramName: "file",
                    autoProcessQueue: true,
                    maxFilesize: 250, //MB
                    dictFileTooBig: "File is to big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: "Cancel",
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png",
                    maxFiles: filesLeft,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    init: function() {
                        qDropZone = this;
                    }
                },
                isFileUploading: false,
                isEditingAllowed: true,
                isHideAnswerBlock: false,
                isHideMandatoryBlock: false,
                editCall : false,
                otherOptionInputText : "Other"
            };
        },
        beforeMount: function() {
            EventBus.$emit("menu-selection-changed", "listquestion");
        },
        mounted: function() {
            self = this;
            self.listSurvey();
            if (self.$route.params.id > 0) {
                // Fetch Question by Id.
                self.fetchQuestionById(self.$route.params.id);
            }
            self.setInputFilter(document.getElementById("questionPosition"), function(value) {
                return /^\d*$/.test(value); });
        },
        watch: {
            questionObject: {
                handler(object) {
                    if (object.question.length > 0) {
                        self.questionError = "";
                    }
                    if (object.survey_id.length > 0) {
                        self.surveyError = "";
                    }
                    if (object.section.length > 0) {
                        self.sectionError = "";
                    }
                    if (object.question_type.length > 0) {
                        self.questionTypeError = "";
                    }
                },
                deep: true
            },
            "questionObject.question_type": function(type) {
                self.isHideAnswerBlock = self.isHideMandatoryBlock = false;
                if(type == "free" || type == "attachment") {
                    self.isHideAnswerBlock = true;
                }
                if(type == "note") {
                    self.isHideMandatoryBlock = self.isHideAnswerBlock = true;
                    self.questionObject.is_required = 0;
                }
            },
            "questionObject.other_option": function(value) {
                if (self.editCall == true) {
                    self.addAnswer('edit_call');
                } else {
                    self.addAnswer('other_option');
                }
            }
        },
        methods: {
            setInputFilter : function(textbox, inputFilter) {
                ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
                    textbox.addEventListener(event, function() {
                        if (inputFilter(this.value)) {
                            this.oldValue = this.value;
                            this.oldSelectionStart = this.selectionStart;
                            this.oldSelectionEnd = this.selectionEnd;
                        } else if (this.hasOwnProperty("oldValue")) {
                            this.value = this.oldValue;
                            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                        } else {
                            this.value = "";
                        }
                    });
                });
            },
            changeQuestionPositionInput : function() {
                self.questionPositionError = "";
                if (self.questionPosition > self.maxPosition) {
                    self.questionPositionError = "You can not enter position greater than " + self.maxPosition;
                    self.questionPosition = self.maxPosition;
                }
                if(self.questionPosition[0] === "0" ) {
                    // self.questionPosition = "";
                    self.placeholderPosition = "Enter Position";
                }
            },
            changeQuestionPosition : function (position) {

            },
            changeAnswerInput : function(ansOptions) {
                if (ansOptions.is_other_option == 1) {
                    self.otherOptionInputText = ansOptions.answeTxt;
                }
            },
            changeOtherOption : function () {

            },
            removeAnswer: function(index, ansOption) {
                if (ansOption.id && !self.isEditingAllowed) {
                    // Checking if this Survey is already attempted by any User, If yes then we cannot allow Admin to Delete this answer.
                } else {
                    self.questionObject.answerOptions.splice(index, 1);
                }
            },
            addAnswer: function(flag) {
                if (!self.validateForm()) {
                    self.showSAlert(
                        "Please fill all Question Details.",
                        self.saOpTypes.INFO,
                        function() {}
                    );
                    self.questionObject.other_option = 0;
                    return;
                }
                if (flag == "edit_call") {
                    self.editCall = false;
                } else if (flag == 'add_answer') {
                    if (self.questionObject.other_option == 1) {
                        self.questionObject.answerOptions.pop();
                        var object = {};
                        object.answeTxt = "";
                        object.is_required = 0;
                        object.is_other_option = 0;
                        self.questionObject.answerOptions.push(object);
                        var object = {};
                        object.answeTxt = self.otherOptionInputText;
                        object.is_required = 0;
                        object.is_other_option = 1;
                        self.questionObject.answerOptions.push(object);
                    } else {
                        var object = {};
                        object.answeTxt = "";
                        object.is_required = 0;
                        object.is_other_option = 0;
                        self.questionObject.answerOptions.push(object);
                    }

                } else {
                    if (self.questionObject.other_option == 1) {
                        var object = {};
                        object.answeTxt = self.otherOptionInputText;
                        object.is_required = 0;
                        object.is_other_option = 1;
                        self.questionObject.answerOptions.push(object);
                    } else {
                        self.questionObject.answerOptions.pop();
                    }

                }

                // Following sets Autofocus on the newly added Answer field.
                self.$nextTick(() => {
                    var lastIndex = self.questionObject.answerOptions.length - 1;
                    self.$refs.ansInputRef[lastIndex].focus();
                });
            },
            listSurvey: function(config) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get("survey/list", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.surveyOptions = response.data.content.row.data;
                            for (var i = 0; i < self.surveyOptions.length; i++) {
                                var surveyObject = self.surveyOptions[i];
                                if (surveyObject.id == self.$route.params.survey_id) {
                                    self.selectedSurvey.id = surveyObject.id;
                                    self.selectedSurvey.name = surveyObject.name;
                                    self.questionObject.survey_id = surveyObject.id;
                                    break;
                                }
                            }
                        }
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function() {});
                    });
            },
            addQuestion: function(is_save_continue = false) {
                // To Do
                // Add Validations
                if (!self.validateForm()) {
                    return;
                }

                if (!self.validateAnswer()) {
                    return;
                }

                if (self.questionObject.other_option == null || self.questionObject.other_option == '' || typeof self.questionObject.other_option == "undefined") {
                    self.questionObject.other_option = 0;
                }
                var data = {
                    id: self.$route.params.id > 0 ? self.$route.params.id : 0,
                    question: self.questionObject.question,
                    survey_id: self.questionObject.survey_id,
                    section: "survey", //self.questionObject.section, // Now allowing only Survey questions to be added by Admin, User info questions will be static.
                    question_type: self.questionObject.question_type,
                    question_note: self.questionObject.question_note,
                    comment: self.questionObject.comment,
                    is_required: self.questionObject.is_required,
                    answer_options: self.questionObject.answerOptions,
                    image_path: self.imagesPaths,
                    image_type: self.imagesType,
                    image_size: self.imagesSize,
                    other_option : self.questionObject.other_option,
                    question_position : self.questionPosition,
                };
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var apiUrl = "question/add";
                if (self.$route.params.id > 0) {
                    apiUrl = "question/edit";
                }
                self.showLoader();
                HTTP.post(apiUrl, data, config)
                    .then(function(response) {
                        self.hideLoader();
                        self.removeAllFiles();
                        if (response.data.code == 200) {
                            self.showSAlert(
                                response.data.content.message,
                                self.saOpTypes.SUCCESS,
                                function() {
                                    if (is_save_continue) {
                                        self.questionObject.question = "";
                                        self.questionObject.is_required = 0;
                                        self.questionObject.question_note = "";
                                        self.questionObject.answerOptions = [];
                                        self.imagesPaths = [];
                                        self.imagesType = [];
                                        self.imagesSize = [];
                                        return;
                                    }
                                    self.$router.push({ name: "DraggableQuestionList" });
                                }
                            );
                        }
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        console.log(err);
                        self.removeAllFiles();
                        self.catchHandler(err, function(params) {
                            console.log(params);
                        });
                    });
            },
            fetchQuestionById: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id
                    }
                };
                self.showLoader();
                HTTP.get("question/fetchbyid", config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            if (response.data.content.length > 0) {
                                self.questionObject = response.data.content[0];
                                self.selectedSurvey.id = response.data.content[0].survey_id;
                                self.selectedSurvey.name = response.data.content[0].survey_title;
                                self.questionPosition = response.data.content[0].position;
                                self.questionObject.other_option = (response.data.content[0].other_option == 1) ? 1 : 0;
                                if (self.questionObject.other_option == 1) {
                                    self.editCall = true;
                                } else {
                                    self.editCall = false;
                                }
                                if (self.questionObject.images.length > 0) {
                                    filesLeft -= self.questionObject.images.length;
                                    console.log(qDropZone.options.maxFiles);
                                    //qDropZone.options.maxFiles = filesLeft;
                                    console.log(qDropZone.options.maxFiles);
                                    //self.dropzoneOptions.maxFiles = (4 - self.questionObject.images.length);
                                    //self.$refs.dropzone.setOption('maxFiles', (4 - self.questionObject.images.length));
                                    for (var i = 0; i < self.questionObject.images.length; i++) {
                                        var file = self.questionObject.images[i];
                                        self.manuallyAddFile(file);
                                    }
                                }
                            }

                            self.checkIfSurveyAttempted(self.selectedSurvey.id, function(result) {
                                if (result) {
                                    self.isEditingAllowed = false;
                                } else {
                                    self.isEditingAllowed = true;
                                }
                            });
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.hideLoader();
                        self.catchHandler(err, function() {});
                    });
            },
            buttonBack: function() {
                self.$router.push({ name: "DraggableQuestionList" });
            },
            onChangeSurvey: function() {
                self.questionObject.survey_id = self.selectedSurvey.id;
            },
            validateForm: function() {
                var isValidated = true;

                if (self.questionObject.question == "") {
                    self.questionError = "Please Enter Question";
                    isValidated = false;
                }

                if (self.questionObject.survey_id == "") {
                    self.surveyError = "Please Select Survey";
                    isValidated = false;
                }

                if (self.questionObject.section == "") {
                    self.sectionError = "Please Select Section";
                    isValidated = false;
                }

                if (self.questionObject.question_type == "") {
                    self.questionTypeError = "Please Select Question Type";
                    isValidated = false;
                } else {
                    if(self.questionObject.question_type == 'note') {
                        if(!self.questionObject.question_note) {
                            self.questionNoteError = "Please Enter Question Notes.";
                            isValidated = false;
                        }
                    }
                }

                return isValidated;
            },
            validateAnswer: function() {
                var isValidated = true;

                if(!self.isHideAnswerBlock) {
                    if (self.questionObject.answerOptions.length == 0) {
                        alert("Add at least One answer");
                        isValidated = false;
                    } else {
                        if (self.questionObject.answerOptions.length < 2) {
                            alert("Add at least two answer");
                            isValidated = false;
                        } else {
                            var emptyAnswerCount = 0;
                            var checkedAnswer = 0;
                            for (let i = 0; i < self.questionObject.answerOptions.length; i++) {
                                if (self.questionObject.answerOptions[i].answeTxt == "") {
                                    emptyAnswerCount++;
                                }
                                if (self.questionObject.answerOptions[i].is_required == "1") {
                                    checkedAnswer++;
                                }
                            }
                            if (emptyAnswerCount > 0) {
                                alert("Fill answer text.");
                                isValidated = false;
                            }
                            /* if (checkedAnswer == 0) {
                                alert("Please select at least one answer as right answer.");
                                isValidated = false;
                            }
                            if (
                                self.questionObject.question_type == "single" &&
                                checkedAnswer > 1
                            ) {
                                alert(
                                "Only one answer would be right answer in Single answer type."
                                );
                                isValidated = false;
                            }
                            if (
                                self.questionObject.question_type == "multiple" &&
                                checkedAnswer < 2
                            ) {
                                alert("Select at least two answer in Multiple answer type.");
                                isValidated = false;
                            } */
                        }
                    }
                }
                return isValidated;
            },
            /** DropZone */
            uploadDocument: function(file, response) {
                var jsonResponse = JSON.parse(response);
                self.imagesPaths.push(jsonResponse.content);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                // To Do Uncomment the following to fix Max Files error in Vue Dropzone.
                // self.$refs.dropzone.setOption('maxFiles', 4);
            },
            removedSelectedFile: function(file, xhr, error) {
                var removeFile = "";
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    //self.$refs.dropzone.setOption('maxFiles', 4);
                } else {
                    if (typeof file.xhr != "undefined") {
                        var filePath = JSON.parse(file.xhr.response);
                        removeFile = filePath.content;
                        //console.log(removeFile);return false;
                        self.removeFileFromServer(removeFile);
                    }
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                //self.$refs.dropzone.setOption('maxFiles', 4);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function(filePath) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    data: {
                        filePath: filePath
                    }
                };
                HTTP.delete("question/removeimage", config)
                    .then(function(response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function() {});
                    });
            },
            fileError: function(file, xhr, error) {},
            fileAdd: function(file, xhr, error) {
                console.log(qDropZone.options.maxFiles);
                /*if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', 4);
                }*/
            },
            manuallyAddFile: function(file) {
                var type = "image/" + file.extension;
                var fileMeta = {
                    size: file.size,
                    name: file.name,
                    type: type
                };
                self.imagesPaths.push(file.image_path);
                self.imagesType.push(type);
                self.imagesSize.push(file.size);
                self.$refs.dropzone.manuallyAddFile(fileMeta, file.image_full_path);
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.isFileUploading = false;
                } else {
                    self.isFileUploading = true;
                }
            },
            removeAllFiles: function() {
                self.$refs.dropzone.removeAllFiles();
            }
            /** DropZone */
        }
    };
</script>
