<template>
  <div id="app">
    <template v-if="$route.path.indexOf('/backend') >= 0">
      <backend-header></backend-header>
      <div id="wrapper" class="app-sidebar-right">
        <backend-sidebar></backend-sidebar>
        <router-view></router-view>
      </div>
    </template>
    <template v-else>
      <router-view/>
    </template>
  </div>
</template>

<script>
require("@babel/polyfill");

import BackendHeader from '@/components/backend/partials/header';
import BackendFooter from '@/components/backend/partials/footer';
import BackendSidebar from '@/components/backend/partials/sidebar';

export default {
  name: 'App',
  components: {
    'backend-header': BackendHeader,
    'backend-footer': BackendFooter,
    'backend-sidebar': BackendSidebar,
  }
}
</script>