<template>
    <div id="page-content-wrapper" class="admin-content-area survey-add-update">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div v-if="$route.params.id > 0" class="page-name">Edit Survey</div>
                <div v-else class="page-name">Add Survey</div>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <div class="card-body card-body-padding-none">
                    <!-- Forms -->
                    <div class="forms-container">
                        <b-form id="quiz_add_update" class="CustomChangeProfile">
                            <div class="row">
                                <template>
                                    <!-- Survey Name -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Survey Name:</div>
                                            <div class="form-data">
                                                <b-form-input v-model="surveyObject.name" type="text" class="form-control" id="survayName" placeholder="Enter Survey Name"></b-form-input>
                                                <div class="requiredstar">*</div>
                                                <font class="text-danger" v-if="nameError.length > 0">{{ nameError }}</font>
                                                <font class="text-danger" v-if="nameExistsError.length > 0">{{ nameExistsError }}</font>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Survey URL -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">URL:</div>
                                            <div class="form-data">
                                                <b-form-input v-model="surveyObject.unique_code" type="text" class="form-control" id="surveyUniqueCode" placeholder="Enter Survey URL"></b-form-input>
                                                <!-- <slug-input type="text" :value="surveyObject.name" @change="val => surveyObject.unique_code = val" :slugify="customSlugFunction"></slug-input><br/> -->
                                                <div class="requiredstar">*</div>
                                                <font class="text-danger" v-if="uniCodeError.length > 0">{{ uniCodeError }}</font>
                                                <font class="text-danger" v-if="uniCodeExistsError.length > 0">{{ uniCodeExistsError }}</font>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">Department :</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <v-select
                                                v-model="selectedDepartment"
                                                name="department"
                                                placeholder="Select Department"
                                                id="selectedDepartment"
                                                @input="onChangeDepartment()"
                                                :options="departmentOptions"
                                                label="name"
                                            >
                                                <template slot="no-options">Type Department name here</template>
                                                <template slot="option" slot-scope="option">
                                                    <div class="d-center">{{ option.name }}</div>
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">{{ option.name }}</div>
                                                </template>
                                            </v-select>
                                            <font class="text-danger" v-if="departmentError.length > 0">{{ departmentError }}</font>
                                            <div class="requiredstar">*</div>
                                        </div>
                                    </div> -->
                                    
                                    <!-- Survey Heading -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Survey Heading:</div>
                                            <div class="form-data">
                                                <b-form-input
                                                    v-model="surveyObject.heading"
                                                    type="text"
                                                    class="form-control"
                                                    id="surveyHeading"
                                                    placeholder="Enter Survey Heading"
                                                    @input="headingError = ''"
                                                ></b-form-input>
                                                <div class="requiredstar">*</div>
                                                <font class="text-danger" v-if="headingError.length > 0">{{ headingError }}</font>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Note -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Note:</div>
                                            <div class="form-data">
                                                <b-form-input
                                                    v-model="surveyObject.notes"
                                                    type="text"
                                                    class="form-control"
                                                    id="surveyNotes"
                                                    placeholder="Enter Survey Note"
                                                ></b-form-input>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Access to Users -->
                                    <div class="col-lg-12 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Access to Users:</div>
                                            <div class="form-data">
                                                <vue-tags-input
                                                    v-model="surveyObject.userTag"
                                                    :tags="userTagsArr"
                                                    @tags-changed="newTags => userTagsArr = newTags"
                                                    :autocomplete-items="autocompleteItems"
                                                    placeholder="Select User(s)"
                                                    :add-only-from-autocomplete="true"
                                                    :autocomplete-min-length=0
                                                    :autocomplete-always-open="false"
                                                    class="user-access"
                                                />
                                                <font class="text-danger" v-if="emailTagError.length > 0">{{emailTagError}}</font>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Email -->
                                    <div style="clear:both;"></div>
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Email:</div>
                                            <div class="form-data">
                                                <vue-tags-input
                                                    v-model="surveyObject.email"
                                                    :tags="emailTagsArr"
                                                    :validation="validation"
                                                    @tags-changed="newTags => emailTagsArr = newTags"
                                                    @before-adding-tag="checkEmailTag"
                                                    placeholder=""
                                                />
                                                <font class="text-danger" v-if="emailTagError.length > 0">{{emailTagError}}</font>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Company -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Company:</div>
                                            <div class="form-data">
                                                <v-select
                                                        v-model="selectedCompany"
                                                        name="department"
                                                        placeholder="Select Company"
                                                        id="selectedDepartment"
                                                        @input="onChangeLogo()"
                                                        :options="companyOptions"
                                                        label="name"
                                                >
                                                    <template slot="no-options">Type Company name here</template>
                                                    <template slot="option" slot-scope="option">
                                                        <!--<div class="d-center"><img :src="option.logo_path"/></div>-->
                                                        <div class="d-center">{{ option.name }}</div>
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        <div class="selected d-center">{{ option.name }}</div>
                                                    </template>
                                                </v-select>
                                                <font class="text-danger">{{ companyError }}</font>
                                                <div class="requiredstar">*</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Custom Survey Closure Message -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Custom Survey Closure Message:</div>
                                            <div class="form-data">
                                                <b-form-input 
                                                        v-model="surveyObject.customEndMsg" 
                                                        type="text" 
                                                        class="form-control" 
                                                        id="survayCustomEndMsg" 
                                                        @input="customEndMsgError = ''"
                                                        placeholder="Enter Custom Survey Closure Message"
                                                        maxlength="190">
                                                </b-form-input>
                                                <div class="requiredstar">*</div>
                                                <div class="text-danger">{{ customEndMsgError }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Hide 'Back To Survey' Button -->
                                    <div class="col-lg-6 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;">Hide 'Back To Survey' Button:</div>
                                            <div class="form-data">
                                                <b-form-checkbox
                                                        id="surveyHideBackBtn"
                                                        v-model="surveyObject.hideBackBtn"
                                                        value="1"
                                                        unchecked-value="0">
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Description -->
                                    <div class="col-lg-12 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;" for="description">Description:</div>
                                            <div class="form-data">
                                                <tinymce 
                                                    :plugins="['advlist autolink lists link image charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualchars code fullscreen', 'insertdatetime media nonbreaking save table contextmenu directionality','template paste textcolor colorpicker textpattern imagetools toc help emoticons hr codesample']"
                                                    id="description"
                                                    v-model="surveyDescription"
                                                    class= "" 
                                                    ref="tm" 
                                                    :other_options="surveyOptions"
                                                ></tinymce>
                                                <!-- <font class="text-danger">{{surveyDescriptionError}}</font> -->
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Footer Description -->
                                    <div class="col-lg-12 forms-block">
                                        <div class="form-title">
                                            <div class="form-label" style="width: 170px;" for="description">Footer Description:</div>
                                            <div class="form-data">
                                                <tinymce 
                                                    :plugins="['advlist autolink lists link image charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualchars code fullscreen', 'insertdatetime media nonbreaking save table contextmenu directionality','template paste textcolor colorpicker textpattern imagetools toc help emoticons hr codesample']"
                                                    id="footerDescription"
                                                    v-model="footerDescription"
                                                    class= "" 
                                                    ref="tm" 
                                                    :other_options="surveyOptions"
                                                ></tinymce>
                                                <!-- <font class="text-danger">{{surveyDescriptionError}}</font> -->
                                            </div>
                                        </div>
                                    </div>

                                    <!-- TODO uncomment the below to enable Survey capabilities in Survey -->
                                    <!-- <div class="col-6 forms-block">
                                        <div class="col-12 paddingleft0">
                                            <label class="checkbox-inline checkbox-bootstrap checkbox-lg">
                                                <input
                                                    v-model="surveyObject.show_score_on_survey_submit"
                                                    :key="surveyObject.show_score_on_survey_submit"
                                                    type="checkbox"
                                                    name="show_score_on_survey_submit"
                                                />
                                                <span class="checkbox-placeholder"></span>
                                                Show score on Survey submit
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 forms-block">
                                        <label class="checkbox-inline checkbox-bootstrap checkbox-lg">
                                            <input
                                                v-model="surveyObject.show_correct_answer_on_survey_submit"
                                                :key="surveyObject.show_correct_answer_on_survey_submit"
                                                type="checkbox"
                                                name="show_correct_answer_on_survey_submit"
                                            />
                                            <span class="checkbox-placeholder"></span>
                                            Show correct answers after Survey submit
                                        </label>
                                    </div> -->

                                    <!-- Action Buttons -->
                                    <div style="clear:both;"></div>
                                    <div class="col-6 forms-block">
                                        <div class="form-title">
                                            <div class="button-box outline-button QuizButton bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20" style="box-shadow:none;">
                                                <div class="mr-17 pb-2 float-l">
                                                    <b-button size="lg" variant="primary" @click="addSurvey">
                                                        <template v-if="$route.params.id > 0">Update</template>
                                                        <template v-else>Save</template>
                                                    </b-button>
                                                </div>
                                            </div>
                                            <div class="button-box outline-button QuizButton bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20">
                                                <div class="mr-17 pb-2 float-l">
                                                    <b-button size="lg" variant="secondary" @click="buttonBack">Cancel</b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
        <backend-footer></backend-footer>
    </div>
  <!-- /#page-content-wrapper -->
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import BackendFooter from "@/components/backend/partials/footer";
    import { commonMixins } from "../../../mixins/commonMixins";
    import vSelect from "vue-select";
    import SlugInput from "vue-slug-input";

    import EventBus from "../../event-bus";
    import moment from "moment";
    import VueTagsInput from '@johmun/vue-tags-input';
    import tinymce from 'vue-tinymce-editor';
    Vue.use(SlugInput);
    var self;

    export default {
        name: "AddEditSurvey",
        components: {
            "v-select": vSelect,
            "backend-footer": BackendFooter,
            VueTagsInput,
            'tinymce': tinymce,
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: "Add Survey",
                surveyObject: {
                    name: "",
                    unique_code: "",
                    department_id: "",
                    notes: "",
                    email: "",
                    start_date: "",
                    end_date: "",
                    userTag: "",
                    show_score_on_survey_submit: false,
                    show_correct_answer_on_survey_submit: false,
                    heading: "",
                    customEndMsg: '',
                    hideBackBtn: 0
                },
                emailTagsArr: [],
                userTagsArr:[],
                autocompleteItems:[],
                validation: [{
                    classes: 'valid-email',
                    rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
                emailTagError: "",
                userTagError: "",
                nameExists: false,
                nameExistsError: false,
                nameError: "",
                headingError: "",
                uniCodeExists: false,
                uniCodeExistsError: false,
                uniCodeError: "",
                departmentError: "",
                surveyNameHolder: "", // Helps to call Slugify function only when there is a change in the Survey Object's name value.
                surveyUniCodeHolder: "", 
                selectedDepartment: {
                    id: 0,
                    name: ""
                },
                departmentOptions: [{}],
                selectedCompany : {
                    id: 0,
                    name: ""
                },
                companyError : "",
                companyOptions : [{}],
                users : [],
                configStart: {
                    wrap: false, // set wrap to true only when using 'input-group'
                    altFormat: "m/d/Y",
                    altInput: true,
                    dateFormat: "Y-m-d",
                    enableTime: false,
                    maxDate: null
                    //locale: Hindi, // locale for this instance only
                },
                configEnd: {
                    wrap: false, // set wrap to true only when using 'input-group'
                    altFormat: "m/d/Y",
                    altInput: true,
                    dateFormat: "Y-m-d",
                    enableTime: false,
                    minDate: null
                    //locale: Hindi, // locale for this instance only
                },
                emailCount: 1,
                maxEmailCount: 5,
                emailArr: [],

                surveyDescription: "",
                surveyDescriptionError: "",
                footerDescription: "",
                surveyOptions: {
                    format: 'DD/MM/YYYY',
                    minDate: new Date(),
                },
                customEndMsgError: ''
            };
        },
        beforeMount: function() {
            EventBus.$emit("menu-selection-changed", "listsurvey");
        },
        mounted: function() {
            self = this;
            self.listDepartment();
            self.getLogos();
            self.getUsers();
            if (self.$route.params.id > 0) {
                // Fetch Survey by Id.
                self.fetchSurveyById(self.$route.params.id);
            } else {
                self.emailArr = [
                    {
                        email: "",
                        emailError: "",
                    }
                ];
            }
        },
        watch: {
            "surveyObject.name": function(newVal, oldVal) {
                if (newVal.length > 0) {
                    self.nameError = "";
                    if (self.$route.params.id > 0 && newVal == self.surveyNameHolder) {
                    } else {
                        self.checkIfSurveyExists(newVal);
                    }
                }
            },
            "surveyObject.unique_code": function(newVal, oldVal) {
                if (newVal.length > 0) {
                    self.nameError = "";
                    if (self.$route.params.id > 0 && newVal == self.surveyUniCodeHolder) {
                    } else {
                        self.checkIfSurveyUniCodeExists(newVal);
                    }
                }
            },
            "surveyObject.department_id": function(newVal, oldVal) {
                // console.log(newVal);
                if (newVal > 0) {
                    self.departmentError = "";
                }
            }
        },
        methods: {
            getLogos: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get("/logo/get", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.companyOptions = response.data.content;
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            getUsers: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get("/users/get", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.users = response.data.content;
                            self.autocompleteItems = response.data.content.map(a => {
                                return { id: a.id, text: a.name };
                            });
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            onChangeLogo : function () {
                self.companyError = "";
            },
            checkEmailTag: function(obj) {
                self.emailTagError = "";
                self.isValidEmailTag = true;
                var tagEmail = JSON.stringify(obj.tag.text).split('"');
                if(!self.validEmail(tagEmail[1])) {
                    self.isValidEmailTag = false;
                    self.emailTagError = "Please enter valid Email";
                } else obj.addTag();
            },
            addLine: function() {
                // self.emailCount = self.emailCount + 1;
                var index = self.emailArr.length - 1;
                if (self.emailArr[index].email != null && self.emailArr[index].email != '') {
                    if(!self.validEmail(self.emailArr[index].email)) {
                        self.emailArr[index].emailError = "Email Must Be In Proper Email Format.";
                    } else {
                        self.emailArr.push({
                            email: '',
                            emailError: '',
                        })
                        self.emailCount = self.emailCount + 1;
                    }
                }
            },
            validEmail: function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            addSurvey: function() {
                if (!self.validateForm()) {
                    return;
                }
                var emailData = '';

                /* for (var i = 0; i < self.emailArr.length; i++) {
                    if (self.emailArr[i].email != null && self.emailArr[i].email != '') {
                        if(self.validEmail(self.emailArr[i].email)) {
                            if(!emailData.includes(self.emailArr[i].email)) {
                                emailData += (self.emailArr[i].email) + ',';
                            }
                        }
                    }
                } */
                // console.log(JSON.stringify(self.emailTagsArr));return false;
                if(self.emailTagsArr && self.emailTagsArr.length > 0) {
                    for(var index = 0;index<self.emailTagsArr.length;index++) {
                        if(!emailData.includes(self.emailTagsArr[index].text)) {
                            emailData += (self.emailTagsArr[index].text) + ',';
                        }
                    }
                }
                emailData = emailData.replace(/,\s*$/, "");

                var userData = '';
                if(self.userTagsArr && self.userTagsArr.length > 0) {
                    for(var index = 0;index<self.userTagsArr.length;index++) {
                        console.log(self.userTagsArr[index]);
                        userData += (self.userTagsArr[index].id) + ',';
                    }
                }
                userData = userData.replace(/,\s*$/, "");
                
                var data = {
                    id: self.$route.params.id,
                    name: self.surveyObject.name,
                    unique_code: self.surveyObject.unique_code,
                    start_date: self.surveyObject.start_date,
                    end_date: self.surveyObject.end_date,
                    notes: self.surveyObject.notes,
                    email: emailData,
                    users: userData,
                    department_id: self.surveyObject.department_id,
                    show_correct_answer_on_survey_submit: self.surveyObject.show_correct_answer_on_survey_submit,
                    show_score_on_survey_submit: self.surveyObject.show_score_on_survey_submit,
                    company_id : self.selectedCompany.id,
                    survey_description: self.surveyDescription,
                    footer_description: self.footerDescription,
                    heading: self.surveyObject.heading,
                    customEndMsg: self.surveyObject.customEndMsg,
                    hideBackBtn: self.surveyObject.hideBackBtn
                };

                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                var apiUrl = "survey/add";

                if (self.$route.params.id > 0) {
                    apiUrl = "survey/edit";
                }
                self.showLoader();
                HTTP.post(apiUrl, data, config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            self.showSAlert(
                                response.data.content.message,
                                self.saOpTypes.SUCCESS,
                                function() {
                                    self.$router.push("/backend/listsurvey");
                                }
                            );
                        }
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        self.catchHandler(err, function(params) {
                            console.log(params);
                        });
                    });
            },
            fetchSurveyById: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id
                    }
                };
                self.showLoader();
                HTTP.get("survey/fetchbyid", config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            if (response.data.content.length > 0) {
                                self.surveyObject = response.data.content[0];
                                self.selectedCompany = {id : response.data.content[0].company_id, name : response.data.content[0].company_name};
                                self.surveyDescription =  response.data.content[0].survey_description;
                                self.footerDescription =  response.data.content[0].footer_description;
                                self.emailTagsArr = [];
                                if(self.surveyObject.email && self.surveyObject.email.length > 0) {
                                    var splitEmailArr = response.data.content[0].email.split(",");
                                    if(splitEmailArr.length > 0) {
                                        for(var i=0; i < splitEmailArr.length; i++) {
                                            self.emailTagsArr.push(
                                                {
                                                    text: splitEmailArr[i],
                                                }
                                            )
                                        }
                                    }
                                }
                                self.surveyObject.email = "";
                                self.surveyNameHolder = self.surveyObject.name;
                                self.surveyUniCodeHolder = self.surveyObject.unique_code;
                                // self.selectedDepartment.id = response.data.content[0].department_id;
                                self.selectedDepartment.name = response.data.content[0].department_name;
                                        console.log(self.surveyObject.users.length);
                                self.userTagsArr = [];
                                if(self.surveyObject.users && self.surveyObject.users.length > 0) {
                                    for(var i=0; i < self.surveyObject.users.length; i++) {
                                        self.userTagsArr.push(
                                            {
                                                id: self.surveyObject.users[i].id,
                                                text: self.surveyObject.users[i].text
                                            }
                                        );
                                    }    
                                }
                                 
                            }
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.hideLoader();
                        self.catchHandler(err, function() {});
                    });
            },
            buttonBack: function() {
                self.$router.push("/backend/listsurvey");
            },
            onChangeDepartment: function() {
                if (self.selectedDepartment != null) {
                    self.surveyObject.department_id = self.selectedDepartment.id;
                } else {
                    self.surveyObject.department_id = "";
                }
            },
            onStartChange(selectedDates, dateStr, instance) {
                if (self.configEnd.maxDate === dateStr) {
                    self.configEnd.maxDate = moment(dateStr).add(15, "days").format("YYYY-MM-DD");
                }
                self.$set(self.configEnd, "minDate", dateStr);
            },
            onEndChange(selectedDates, dateStr, instance) {
                if (self.configStart.minDate === dateStr) {
                    self.configStart.minDate = moment(dateStr).add(-15, "days").format("YYYY-MM-DD");
                    //self.configStart.minDate = dateStr;
                }
                self.$set(self.configStart, "maxDate", dateStr);
            },
            customSlugFunction: function() {
                var surveyName = self.surveyObject.name;
                if (
                    (surveyName.length == 0 || self.surveyObject.unique_code.length > 0) &&
                    self.surveyNameHolder == self.surveyObject.name
                ) {
                    return;
                }

                self.surveyNameHolder = self.surveyObject.name;

                if (surveyName.length > 10) {
                    surveyName = surveyName.substring(0, 10);
                }
                surveyName = this.slugify(surveyName);

                self.surveyObject.unique_code =
                    surveyName +
                    "-" +
                    (
                    Date.now().toString(36) +
                    Math.random()
                        .toString(36)
                        .substr(2, 5)
                    ).toUpperCase();
            },
            slugify: function(string) {
                const a =
                    "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
                const b =
                    "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------";
                const p = new RegExp(a.split("").join("|"), "g");

                return string
                    .toString()
                    .toLowerCase()
                    .replace(/\s+/g, "-") // Replace spaces with -
                    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                    .replace(/&/g, "-and-") // Replace & with 'and'
                    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
                    .replace(/\-\-+/g, "-") // Replace multiple - with single -
                    .replace(/^-+/, "") // Trim - from start of text
                    .replace(/-+$/, ""); // Trim - from end of text
            },
            validateForm() {
                var isValidated = true;

                if (self.nameExists) {
                    isValidated = false;
                }
                if (self.uniCodeExists) {
                    isValidated = false;
                }

                if (self.surveyObject.name == "") {
                    self.nameError = "Please Enter Survey Name";
                    isValidated = false;
                }
                if (self.surveyObject.heading == "") {
                    self.headingError = "Please Enter Survey Heading";
                    isValidated = false;
                }
                if (self.surveyObject.unique_code == "") {
                    self.uniCodeError = "Please Enter Survey URL";
                    isValidated = false;
                }

                if (self.selectedCompany.id == "" || self.selectedCompany.id == null || typeof self.selectedCompany.id == "undefined") {
                    self.companyError = "Please Select Company";
                    isValidated = false;
                }

                /* if (self.surveyObject.department_id == "") {
                    self.departmentError = "Please Select Survey Department";
                    isValidated = false;
                } else {
                    self.departmentError = "";
                } */

                if (!self.surveyObject.customEndMsg) {
                    self.customEndMsgError = "Please Enter Custom Survey Closure Message";
                    isValidated = false;
                }

                return isValidated;
            },
            listDepartment: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get("department/list/all", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.departmentOptions = response.data.content.row;
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            checkIfSurveyUniCodeExists: function(name) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_uni_code: name
                    }
                };

                HTTP.get("survey/checkifexistsbyunicode", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.uniCodeExistsError = response.data.message;
                            self.uniCodeExists = true;
                        } else if (response.data.code == 404) {
                            self.uniCodeExistsError = "";
                            self.uniCodeExists = false;
                        }
                    })
                    .catch(function(err) {
                        self.uniCodeExistsError = "";
                        self.uniCodeExists = false;
                    // self.catchHandler(err, function() {});
                    });
            },
            checkIfSurveyExists: function(name) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_name: name
                    }
                };

                HTTP.get("survey/checkifexistsbyname", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.nameExistsError = response.data.message;
                            self.nameExists = true;
                        } else if (response.data.code == 404) {
                            self.nameExistsError = "";
                            self.nameExists = false;
                        }
                    })
                    .catch(function(err) {
                        self.nameExistsError = "";
                        self.nameExists = false;
                    // self.catchHandler(err, function() {});
                    });
            },
        }
    };
</script>
<style scoped>
.survey-add-update .form-title {
    display: flex;
}
.survey-add-update .form-data {
    flex: 1;
    position: relative;
}
.survey-add-update .form-data .requiredstar { 
    right: -12px;
}
.survey-add-update .vue-tags-input[data-v-61d92e31] {
    max-width: unset;
}
</style>
