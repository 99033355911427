<template>
    <div class="loginbg">
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="loginform">
                        <div class="loginlogo">
                            <img src="/static/images/login-logo.png" alt="Velocity Vehicle Group" />
                        </div>
                        <form>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input v-model="username" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address"/>
                                <!-- <font class="text-danger" v-html="usernameError"></font> -->
                                <div class="c-error">{{usernameError}}</div>
                                <small v-if="errors.username" class="has-text-danger">{{ errors.username[0] }}</small>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter password"/>
                                <!-- <font class="text-danger" v-html="passwordError"></font> -->
                                <div class="c-error">{{passwordError}}</div>
                                <small v-if="errors.password" class="has-text-danger">{{ errors.password[0] }}</small>
                                <small v-if="errors.invalidcreds" class="has-text-danger">{{ errors.invalidcreds[0] }}</small>
                            </div>
                            <!-- <button v-on:click.prevent="login()" type="submit" class="btn btn-primary">Login</button> -->
                            <button :disabled="disableButtons.loginButton" id="loginbtn" type="submit" class="btn btn-primary" v-on:click.prevent="login()">{{buttonNames.loginButton}}</button>
                            <a :href="forgot_pass_url" target="_blank">Forgot password?</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { HTTP } from "../../http-common";
    import EventBus from "../event-bus";
    import { commonMixins } from "../../mixins/commonMixins";

    var self;
    export default {
        name: "Login",
        data() {
            return {
                username: "",
                password: "",
                error: "",
                errors: {},
                // Frontend Errors
                usernameError: "",
                passwordError: "",
                forgot_pass_url: "",
                buttonNames : {
                    loginButton : 'Login',
                },

                disableButtons : {
                    loginButton : false,
                },
            };
        },
        mixins: [commonMixins],
        beforeMount: function() {
            self = this;
        },
        mounted: function() {
            self = this;
            $("input").keypress(function(e) {
                if (e.keyCode === 13) {
                    if (e.target.id == "exampleInputEmail1" || e.target.id == "exampleInputPassword1") {
                        e.preventDefault();
                        //   console.log("Enter Triggered");
                        self.login();
                    }
                }
            });
            self.forgot_pass_url =
            process.env.VUE_APP_HR_URL + "?identify=" + window.location.href;
        },
        methods: {
            validation: function() {
                var checked = true;
                if (!self.username) {
                    self.usernameError = "Email address Required.";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.password) {
                    self.passwordError = "Password Required.";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            validEmail: function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            login: function() {
                self.usernameError = "";
                self.passwordError = "";
                self.error = "";
                // self.validation();
                if (self.validation()) {
                    self.buttonNames.loginButton = "Login...";
                    self.disableButtons.loginButton = true;
                    var data = {
                        username: self.username,
                        password: self.password
                    };
                    HTTP.post("/login", data)
                        .then(function(response) {
                            self.buttonNames.loginButton = "Login";
                            self.disableButtons.loginButton = false;
                            if (response.data.code == "200") {
                                window.localStorage.setItem("api_token", response.data.content.api_token);
                                window.localStorage.setItem("user_obj", JSON.stringify(response.data.content.user_obj));
                                window.localStorage.setItem("current_location_view", "front_end");
                                //self.$router.push("/closeticket");
                                window.location.href = "/backend/listsurvey";

                                var userObj = JSON.parse(localStorage.getItem("user_obj"));
                                if (userObj != null) {
                                    EventBus.$emit("login-user-info", userObj.email, userObj.first_name, userObj.last_name);
                                }
                            }
                            if (response.data.code == "201") {
                                self.errors = { username: [response.data.content] };
                                self.disabledSmt = 0;
                            }
                        })
                        .catch(function(err) {
                            self.buttonNames.loginButton = "Login";
                            self.disableButtons.loginButton = false;
                            self.disabledSmt = 0;
                            window.localStorage.removeItem("user_obj");
                            window.localStorage.removeItem("access_token");
                            //alert('Invalid Credentials');

                            if (err.response.status == 422) {
                                self.errors = err.response.data;
                            }
                            if (err.response.status == 400) {
                                self.errors = { invalidcreds: ["Invalid Credentials."] };
                            }
                            self.error = err.response.data.message;
                            //self.catchHandler(err, function() {});
                        });
                }
            }
        }
    };
</script>
<style>
</style>
