<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div v-if="$route.params.id > 0" class="page-name">Edit User</div>
                <div v-else class="page-name">Add User</div>
                <button id="exportAnsweredSurvey" type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
            </div>
             <!--page content-->
            <div class="admin-page-content">
                <div class="card-body card-body-padding-none">
                    <!-- Forms -->
                    <div class="forms-container">
                        <div class="row no-margin">
                            <b-form id="user_add_update" class="CustomChangeProfile">
                                <!-- First Name -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">First Name:</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <b-form-input v-model="firstName" type="text" class="form-control" id="firstName" placeholder="Enter First Name"></b-form-input>
                                        <div class="requiredstar">*</div>
                                        <font class="text-danger" v-if="firstNameError.length > 0">{{ firstNameError }}</font>
                                    </div>
                                </div>
                                <!-- Last Name -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">Last Name:</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <b-form-input v-model="lastName" type="text" class="form-control" id="lastName" placeholder="Enter Last Name"></b-form-input>
                                        <div class="requiredstar">*</div>
                                        <font class="text-danger" v-if="lastNameError.length > 0">{{ lastNameError }}</font>
                                    </div>
                                </div>
                                <!-- Email -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">Email:</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <b-form-input v-model="email" type="text" class="form-control" id="email" placeholder="Enter Email"></b-form-input>
                                        <div class="requiredstar">*</div>
                                        <font class="text-danger" v-if="emailError.length > 0">{{ emailError }}</font>
                                    </div>
                                </div>
                                <!-- DayforceId -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">DayforceId:</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <b-form-input v-model="dayforceId" type="text" class="form-control" id="dayforceId" placeholder="Enter dayforceId"></b-form-input>
                                        <div class="requiredstar">*</div>
                                        <font class="text-danger" v-if="dayforceIdError.length > 0">{{ dayforceIdError }}</font>
                                    </div>
                                </div>

                                <div style="clear:both;"></div>
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="button-box outline-button QuizButton bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20" style="box-shadow:none;">
                                            <div class="mr-17 pb-2 float-l">
                                                <b-button size="lg" variant="primary" @click="addUpdateUser">
                                                    <template v-if="$route.params.id > 0">Update</template>
                                                    <template v-else>Save</template>
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 paddingleft0">
                                        <div class="button-box outline-button QuizButton bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20">
                                            <div class="mr-17 pb-2 float-l">
                                                <b-button size="lg" variant="secondary" @click="buttonBack">Cancel</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <backend-footer></backend-footer>
    </div>
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import BackendFooter from "@/components/backend/partials/footer";
    import { commonMixins } from "../../../mixins/commonMixins";
    import vSelect from "vue-select";
    import SlugInput from "vue-slug-input";

    import EventBus from "../../event-bus";
    import moment from "moment";
    import VueTagsInput from '@johmun/vue-tags-input';
    Vue.use(SlugInput);
    var self;

    export default {
        name: "AddUpdateUser",
        components: {
            "v-select": vSelect,
            "backend-footer": BackendFooter,
            VueTagsInput
        },
        mixins: [commonMixins],
        data() {
            return {
                firstName: "",
                firstNameError: "",
                lastName: "",
                lastNameError: "",
                email: "",
                emailError: "",
                dayforceId: "",
                dayforceIdError: "",
            }
        },
        beforeMount: function() {
            self = this;
            EventBus.$emit("menu-selection-changed", "listuser");
        },
        mounted: function() {
            if (self.$route.params.id > 0) {
                // Fetch User by Id.
                self.fetchUserById(self.$route.params.id);
            }
        },
        watch: {
            email: function(email) {
                if (email.length > 3) {
                    if (!self.validEmail(self.email)) {
                        self.emailError = 'Please Enter Valid Email';
                    } else {
                        self.checkUserInSSO();
                    }
                }
            },
        },
        methods: {
            buttonBack: function() {
                self.$router.push('/backend/user/list');
            },
            checkUserInSSO: function() {
                self.firstNameError = "";
                self.lastNameError = "";
                self.emailError = "";
                self.dayforceIdError = "";

                let updateFlag = false;
                let userId = 0;

                 if(self.$route.params.id > 0) {
                    updateFlag = true;
                    userId = self.$route.params.id;
                } else {
                    updateFlag = false;
                }
                var data = {
                    email : self.email,
                    update_flag : updateFlag,
                    user_id : userId,
                }
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.post('user/checkinsso', data, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            if(response.data.message && response.data.message != 'undefined') {
                                self.firstName = response.data.result.content.first_name;
                                self.lastName = response.data.result.content.last_name;
                                self.dayforceId = response.data.result.content.emp_id;
                                self.showSAlert(response.data.message,'success',false);
                            }
                        }
                    })
                    .catch(function (err) {
                        // console.log(err);
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                self.catchHandler(error);
                            }
                            self.error = err.response.data.message;
                        }
                    })
            },
            fetchUserById: function(id) {
                var fields = {
                    user_id: id,
                };
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.post('/userdetailbyid/get', fields, config)
                    .then(response => {
                        self.firstName = response.data.content.first_name;
                        self.lastName = response.data.content.last_name;
                        self.dayforceId = response.data.content.dayforce_id;
                        self.email = response.data.content.email;
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            //self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                //self.$router.push("/login");
                            }
                            //self.error = err.response.data.message;
                        }
                    });
            },
            checkForm: function() {
                var checked = true;
                self.firstNameError = "";
                self.lastNameError = "";
                self.emailError = "";
                self.dayforceIdError = "";

                if (!self.firstName) {
                    self.firstNameError = "First Name Required";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.lastName) {
                    self.lastNameError = "Last Name Required";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.email) {
                    self.emailError = "Email Required";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please Enter Valid Email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dayforceId) {
                    self.dayforceIdError = "DayforceId Required";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addUpdateUser: function() {
                setTimeout(() => {
                    self.checkForm();
                    if (self.checkForm()) {
                        var data = {
                            id: self.$route.params.id,
                            first_name: self.firstName,
                            last_name: self.lastName,
                            email: self.email,
                            dayforce_id: self.dayforceId,
                        };

                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        self.showLoader();
                        HTTP.post('/user/add/edit', data, config)
                            .then(function(response) {
                                self.hideLoader();
                                if (response.data.code == 200) {
                                    self.showSAlert(
                                        response.data.message,
                                        self.saOpTypes.SUCCESS,
                                        function() {
                                            self.$router.push('/backend/user/list');
                                        }
                                    );
                                }
                            })
                            .catch(function(err) {
                                self.hideLoader();
                                self.catchHandler(err, function(params) {
                                    console.log(params);
                                });
                            });
                    }
                }, 200);
            },
        }
    }
</script> 