<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div class="page-name quiz-page-name">
                    {{ pageTitle }}
                    <button type="submit" v-on:click.prevent="addSurvey" class="btn btn-primary waves-effect pull-right">Add</button>
                </div>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <template>
                    <vue-good-table
                        ref="surveytable"
                        :columns="columns"
                        :rows="rows"
                        mode="remote"
                        :search-options="{
                            enabled: false
                        }"
                        @on-page-change="onPageChange"
                        @on-selected-rows-change="selectionChanged"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        :totalRows="totalRecords"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            pageLabel: 'Page',
                            position: 'bottom',
                            perPage: serverParams.perPage,
                            perPageDropdown: [25, 50, 100, 200 ,500],
                            setCurrentPage: serverParams.setCurrentPage,
                            dropdownAllowAll: false,
                        }"
                    >
                        <!-- Custome Pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="vgt-wrap__footer crm">
                            <div  class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                <v-select title="Select Per Page"
                                    v-model="serverParams.perPage"
                                    @change="changedValueTask"
                                    @input="changedValueTaskCustomPerPageTask()"
                                    name="customPerPage"
                                    id="customPerPage"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="serverParams.perPageDropdown"
                                    placeholder="Select Per Page">
                                </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                <p class="pageRowCount">{{paginationDisplayRowsTask}} of {{totalRecords}}</p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                <ul class="pagination">
                                    <li class="FirstPage" title="First Page">
                                    <template v-if="Number(serverParams.page) == 1">
                                        <a class="page-link" href="javascript:void(0)">1</a>
                                    </template>
                                    <template v-else>
                                        <a v-on:click="onPerticularPageClickTask(1)" class="page-link" href="javascript:void(0)">1</a>
                                    </template>
                                    </li>
                                    <li class="page-item PreviousPage" title="Previous Page">
                                    <template v-if="Number(serverParams.page) == 1">
                                        <a class="page-link" href="javascript:void(0)" tabindex="-1"><i class="fa fa-angle-left"></i></a>
                                    </template>
                                    <template v-else>
                                        <a class="page-link" href="javascript:void(0)" tabindex="-1" v-on:click="onPerticularPageClickTask(Number(serverParams.page)-1)" ><i class="fa fa-angle-left"></i></a>
                                    </template>
                                    </li>
                                    <template v-if="pageNumberListTask() < displayPageTask">
                                    <template v-for="(n, index) in pageNumberListTask()">
                                        <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                        <a class="page-link" href="javascript:void(0)">
                                            {{n}}
                                        </a>
                                        </li>
                                        <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                        <a class="page-link" href="javascript:void(0)">
                                            {{n}}
                                        </a>
                                        </li>
                                    </template>
                                    </template>
                                    <template v-else>
                                        <!--End New Implementation-->
                                        <template v-if="Number(serverParams.page) <= pageCompareTask">
                                        <template v-for="(n, index) in displayPageTask">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                        </template>
                                        </template>
                                        <template v-else-if="Number(serverParams.page) >= (pageNumberListTask() - (pageCompareTask-1))">
                                        <template v-for="(n, index) in pageNumberListTask()">
                                            <template v-if="n > (pageNumberListTask() - displayPageTask)">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                                </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                                </a>
                                            </li>
                                            </template>
                                        </template>
                                        </template>
                                        <template v-else>
                                        <template v-for="(n, index) in pageNumberListTask()" v-if="(n >= (Number(serverParams.page) - (pageCompareTask -1))) && (n <= (Number(serverParams.page) + (pageCompareTask -1)))">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                        </template>
                                        </template>
                                    </template>
                                    <li class="page-item NextPage" title="Next Page">
                                    <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                        <a class="page-link" href="javascript:void(0)"><i class="fa fa-angle-right"></i></a>
                                    </template>
                                    <template v-else>
                                        <a class="page-link" href="javascript:void(0)" v-on:click="onPerticularPageClickTask(Number(serverParams.page)+1)"><i class="fa fa-angle-right"></i></a>
                                    </template>
                                    </li>
                                    <li class="LastPage" title="Last Page">
                                    <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                        <a class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                    </template>
                                    <template v-else>
                                        <a v-on:click="onPerticularPageClickTask(pageNumberListTask())" class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                    </template>
                                    </li>
                                </ul>
                                </div>
                                <div class="mt-2 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="mt-1 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                <input @keyup.enter="jumpToPageInTask" v-model="jumpToPageTask" type="text" class="form-control form-control-sm" name="jumpToPageTask" id="jumpToPageTask" placeholder="Page">
                                </div>
                            </div>
                            </div>
                        </template>
                        <!-- End Custome Pagination -->
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'action'">
                                <b-button class="myButton" @click="editSurvey(props)">
                                    <i class="fa fa-edit"></i>
                                </b-button>
                                <b-button class="myButton" @click="deleteSurvey(props)">
                                    <i class="fa fa-trash-o" style="color:red"></i>
                                </b-button>
                            </span>
                            <span v-else>{{props.formattedRow[props.column.field]}}</span>
                        </template>
                    </vue-good-table>
                </template>
            </div>
            <!--page content-->
        </div>
        <backend-footer></backend-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import { commonMixins } from "../../../mixins/commonMixins";
    import BackendFooter from "@/components/backend/partials/footer";
    import { VueGoodTable } from "vue-good-table";
    import "vue-good-table/dist/vue-good-table.css";
    import EventBus from "../../event-bus";
    import vSelect from 'vue-select';
    Vue.use(VueGoodTable);
    var self;

    export default {
        name: "SurveyList",
        components: {
            "backend-footer": BackendFooter,
            "vue-good-table": VueGoodTable,
            'v-select': vSelect,
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: "Survey List",
                firstLoad: 0,
                columns: [
                    {
                        label: "Name",
                        field: "name",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Survey Name", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Heading",
                        field: "heading",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Survey Heading", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Survey Url",
                        field: "unique_code",
                        filterable: false,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Unique Code", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Questions",
                        field: "question_count",
                        filterable: false,
                        filterOptions: {
                            enabled: false, // enable filter for this column
                            placeholder: "Question Count", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Users",
                        field: "user_count",
                        filterable: false,
                        filterOptions: {
                            enabled: false, // enable filter for this column
                            placeholder: "User Count", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    /* {
                        label: "Start Date",
                        field: "start_date",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "MM/DD/YYYY", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "End Date",
                        field: "end_date",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "MM/DD/YYYY", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    }, */
                    {
                        label: "Action",
                        field: "action",
                        sortable: false,
                        thClass: "disable-sorting"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {
                        name: "",
                        department_name: "",
                        start_date: "",
                        end_date: ""
                    },
                    sort: {
                        field: "id",
                        type: "asc"
                    },
                    page: 1,
                    perPage: 25,
                    setCurrentPage: 1,
                    perPageDropdown : [25, 50, 100, 200, 500],
                },
                displayPageTask: 7,
                pageCompareTask: 0,
                paginationDisplayRowsTask: '',
                jumpToPageTask: 0,
            };
        },
        beforeMount: function() {
            self = this;
            EventBus.$emit("menu-selection-changed", "listsurvey");
        },
        mounted: function() {
            this.jumpToPageTask = this.serverParams.page;
            this.pageCompareTask = Math.ceil(this.displayPageTask / 2);
            $("#jumpToPageTask").on('keyup', function (e) {
                if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
                    self.jumpToInputPageTask(self.jumpToPageTask)
                }
            });
            self.listSurvey();
        },
        methods: {
            addSurvey: function() {
                self.$router.push("/backend/addsurvey");
            },
            changedValueTask: function (value) {
                /*console.log(value);*/
            },
            jumpToPageInTask: function (e) {
                self.jumpToInputPageTask(self.jumpToPageTask);
            },
            changedValueTaskCustomPerPageTask: function () {
                self.jumpToInputPageTask(self.serverParams.page);
                self.listSurvey();
            },
            pageNumberListTask: function () {
                var totalRecord = self.totalRecords;
                var count = 0;
                for (var i = 0; totalRecord > 0; i++) {
                    totalRecord = totalRecord - self.serverParams.perPage;
                    count++;
                }
                return count;
            },
            jumpToInputPageTask: function (pageNumber) {
                var reg = /^\d+$/;
                if (pageNumber <= 0 || pageNumber == '') {
                    pageNumber = 1;
                } else if (pageNumber > self.pageNumberListTask()) {
                    pageNumber = self.pageNumberListTask();
                    if (pageNumber <= 0 || pageNumber == '') {
                        pageNumber = 1;
                    }
                } else if (!String(self.jumpToPageTask).match(reg)) {
                    pageNumber = 1;
                }
                self.onPerticularPageClickTask(pageNumber);
            },
            onPerticularPageClickTask: function (pageNumber) {
                self.serverParams.page = pageNumber;
                self.jumpToPageTask = pageNumber;
                self.listSurvey();
            },
            editSurvey(params) {
                var id = params.row.id;
                self.$router.push({ path: `/backend/editsurvey/${id}` });
            },
            deleteSurvey(params) {
                var id = params.row.id;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };


                HTTP.delete("survey/delete", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.showSAlert(
                                response.data.content.message,
                                self.saOpTypes.SUCCESS,
                                function() {
                                    self.listSurvey();
                                }
                            );
                            let listSurveyLocalStorage = JSON.parse(localStorage.getItem('listQuestionSelectedSurvey'));
                            if (listSurveyLocalStorage != null && listSurveyLocalStorage !== "" && typeof listSurveyLocalStorage !== 'undefined') {
                                if (id == JSON.parse(localStorage.getItem("listQuestionSelectedSurvey")).id) {
                                    localStorage.removeItem("listQuestionSelectedSurvey");
                                }
                            }
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            listSurvey: function() {
                self.firstLoad = 1;
                self.serverParams.setCurrentPage = self.serverParams.page;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        page: self.serverParams.page,
                        perPage: self.serverParams.perPage,
                        filters: self.serverParams.columnFilters,
                        sort: self.serverParams.sort
                    }
                };
                self.showLoader();
                HTTP.get("survey/list", config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            self.rows = response.data.content.row.data;
                            self.totalRecords = response.data.content.total_records;
                        }
                        self.paginationDisplayRowsTask = self.getRecords(self.serverParams.page, self.serverParams.perPage, self.totalRecords);
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.hideLoader();
                        self.catchHandler(err, function() {});
                    });
            },
            onPageChange(params) {
                self.updateParams({ page: params.currentPage });
                self.listSurvey();
            },
            updateParams(newProps) {
                self.serverParams = Object.assign({}, self.serverParams, newProps);
            },
            onPerPageChange(params) {
                self.updateParams({ perPage: params.currentPerPage });
                self.totalRecords = self.totalRecords;
                self.listSurvey();
            },
            onColumnFilter(params) {
                self.isLoading = false;
                self.updateParams(params);
                {
                    field: "name";
                    field: "department_name";
                    field: "unique_code";
                    field: "start_date";
                    field: "end_date";
                }

                this.serverParams.page = 1;
                var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
                var filters = filtersPr.columnFilters;

                if (
                    self.firstLoad == 1 ||
                    filters.name ||
                    filters.department_name ||
                    filters.start_date ||
                    filters.end_date
                ) {
                    this.listSurvey();
                }
            },
            onSortChange(params) {
            //console.log(params);
                this.updateParams({
                    sort: {
                        type: params[0].type,
                        field: params[0].field
                    }
                });
                this.listSurvey();
            },
            onRowClick(params) {
                var id = params.row.id;
                self.$router.push({ path: `/backend/editsurvey/${id}` });
            },
            selectionChanged() {
                var self = this;
            }
        }
    };
</script>
