<template>
    <!--nav-->
    <nav class="navbar navbar-default no-margin app-sidebar-nav">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header fixed-brand">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" id="menu-toggle">
                <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)"><img src="/static/images/logo.png" alt="Velocity Vehicle Group"></a>
        </div>
        <!-- navbar-header-->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav">
                <li class="active">
                    <button class="navbar-toggle collapse in" data-toggle="collapse" id="menu-toggle-2"> 
                        <img src="/static/images/menubar.png" alt="Menu" class="menubar">
                    </button>
                </li>
            </ul>
        </div>
        <!--notification-->
        <!-- Not Required in this project -->
        <!--
        <div class="notification">
            <a href="#">
                <img src="/static/images/notification-bell.png" alt="Notification">
            </a>
        </div>
        -->
        <!--notification-->
        <!--userinfo-->
        <div class="user-info">
            <div class="user-info-container">
                <div class="dropdown">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                        <div class="dropdown-user">
                            <div class="username-letter">{{iconLetters}}</div>
                            <div class="dropdown-user-right">
                                <div class="username">{{firstName}} {{lastName}}</div>
                                <br/>
                                <div class="usermailid">{{username}}</div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu">
                        <!--
                        <a class="dropdown-item" href="#">My Profile</a>
                        <a class="dropdown-item" href="#">Inbox</a>
                        <a class="dropdown-item" href="#">Account Setting</a>
                        -->
                        <a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="logout()">Logout</a>
                    </div>
                </div>
            </div>
        </div>
        <!--userinfo-->
        <!-- bs-example-navbar-collapse-1 -->
    </nav>
    <!--nav-->
</template>

<script>
    
    var self;

    import EventBus from '../../event-bus';

    export default {
        name: 'BackendHeader',
        data() {
            return {
                username : '',
                firstName : '',
                lastName : '',
                iconLetters:'',
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function() {
            var userObj = JSON.parse(localStorage.getItem("user_obj"));
            if (userObj != null) {
                var userId = userObj.id;
                EventBus.$emit('login-user-info', userObj.email, userObj.first_name, userObj.last_name);
            } else {
                self.$router.push({name: 'Login'});
            }
        },
        methods: {
            logout: function () {
               localStorage.clear();
               self.$router.push("/login");
            },
        }
    }

    EventBus.$on('login-user-info', function (user_name, first_name, last_name) {
        
        if(typeof (self) != "undefined") {
            self.username = user_name;
            if(first_name != null || last_name != null){
                self.firstName = first_name;
                self.lastName = last_name;
            }else{
                self.firstName = "NA";
                self.lastName = "";
            }
            //First Two Characters in Upper Case of Username in iconLetters
            self.iconLetters = self.username.substring(0,2).toUpperCase();
        }
    });
</script>
