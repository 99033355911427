<template  xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div>
        <!--header-->
        <section class="quiz-header">
            <a href="#">
                <img :src="companyObj.logo_path" :alt="companyObj.alt_text" />
            </a>
            <h1>{{ survey_info.name }}</h1>
        </section>
        <!--header-->
        <div class="quizcontainer">
            <div class="loading-container" v-show="isSurveySubmitted">
                <div class="loading">
                    <img src="/static/images/loading.gif" />
                </div>
            </div>
            <div class="container" ref="container">
                <template v-if="survey_info.heading != null">
                    <span class="MainTitle MainTitleTop">{{ survey_info.heading }}</span>
                </template>
                <template v-else>
                    <span class="MainTitle MainTitleTop">Survey</span>
                </template>
                <template v-if="survey_info.survey_description != null">
                    <span class="" v-html="survey_info.survey_description"></span>
                </template>
                <div :class="question.question_type != 'note' ? 'quizblock' : 'pb-4 pull-left width-full'" :id="'quizblock'+question.question_id" v-for="(question, q_index) in survey_section" v-bind:key="'quiz-question-'+q_index">
                    <div :class="question.question_type != 'note' ? 'quiz-question' : ''">
                        <!--{{ (q_index + 1) }}. -->{{ question.question }}
                        <!--<div class="answerarea"></div>?-->
                        <template v-if="question.question_type == 'multiple'">(Check all that apply)</template>
                    </div>
                    <div class="quizimage" v-if="typeof(question.images) != 'undefined' && question.images.length > 0">
                        <img v-for="(image, index) in question.images" v-bind:key="'image'+q_index+index" :src="image.image_path" @click="openGallery(question.images, index)"/>
                    </div>
                    <div class="quiz-answer">
                        <div class="answer-block" v-if="question.question_type == 'multiple'">
                            <div class="quiz-checkbox-container">
                                <template v-for="(answer, index) in question.answers">
                                    <div class="quiz-checkbox" v-bind:key="'user-multianswer-'+index">
                                        <div class="custom-control custom-checkbox mb-3">
                                            <input type="checkbox" class="custom-control-input" :id="'customCheck'+q_index+index" v-bind:value="answer.id" v-model="question.selected_answer" @change="totalAnswered(q_index, answer.id)" :name="'customCheck'+q_index+index"/>
                                            <label class="custom-control-label" :for="'customCheck'+q_index+index">
                                                <p> {{ answer.answer }}</p>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div style="clear:both;" v-if="(index+1)%2 == 0" v-bind:key="'user-clear-'+index"></div> -->
                                </template>
                                <div class="clearfix"></div>
                                <textarea v-model="question.other_option_answer" class="col-md-12" :id="'question-' + question.question_id" style="display: none"></textarea>
                            </div>
                        </div>
                        <div class="answer-block" v-else-if="question.question_type == 'single'">
                            <div class="quiz-radio-container">
                                <template v-for="(answer, index) in question.answers">
                                    <div class="quiz-radiobutton" v-bind:key="'user-singleanswer-'+index">
                                        <div class="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                class="custom-control-input"
                                                :id="'customRadio1'+q_index+index"
                                                :value="answer.id"
                                                v-model="question.selected_answer"
                                                @change="totalAnswered(q_index, answer.id)"
                                                :name="'customRadio1'+q_index+index"
                                            />
                                            <label class="custom-control-label" :for="'customRadio1'+q_index+index">
                                                <p> {{ answer.answer }}</p>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div style="clear:both;" v-if="(index+1)%2 == 0" v-bind:key="'user-clear-'+index"></div> -->
                                </template>
                                <div class="clearfix"></div>
                                <textarea v-model="question.other_option_answer" class="col-md-12" :id="'question-' + question.question_id" style="display: none"></textarea>
                            </div>
                        </div>
                        <div class="answer-block" v-else-if="question.question_type == 'free'">
                            <div class="quiz-textarea-container">
                                <textarea 
                                    class="custom-control-textarea"
                                    :id="'customTextArea'+q_index"
                                    :name="'customTextArea'+q_index"
                                    @input="totalAnswered(q_index, -1)"
                                    v-model="question.selected_answer">
                                </textarea>
                            </div>
                        </div>
                        <div class="answer-block" v-else-if="question.question_type == 'attachment'">
                            <div class="quiz-textarea-container">
                                <vue-dropzone
                                    :ref="'dropzone' + q_index"
                                    :id="'dropzone' + q_index"
                                    :options="dropzoneOptions"
                                    @vdropzone-success="uploadDocument"
                                    @vdropzone-removed-file="removedSelectedFile"
                                    @vdropzone-error="fileError"
                                    @vdropzone-file-added="fileAdd(q_index, -1, $event)"
                                    @vdropzone-total-upload-progress="progressOfUload"
                                    v-on:vdropzone-sending="sendingEvent"
                                    :useCustomSlot="true"
                                >
                                    <div class="dropzone-custom-content">
                                        <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                        <div class="subtitle">(Select files from your computer.)</div>
                                    </div>
                                </vue-dropzone>
                            </div>
                        </div>
                        <div class="answer-block" v-else-if="question.question_type == 'note' && question.question_note != null">
                            {{question.question_note}}
                        </div>
                        <div class="quiz-error" v-if="question.is_required == 1" v-show="question.show_error">This question requires an answer.</div>
                    </div>  
                </div>
                <template v-if="survey_info.footer_description != null">
                    <span class="" v-html="survey_info.footer_description"></span>
                </template>
                <template>
                    <div id="recaptcha-main" class="g-recaptcha" :data-sitekey="recaptchaSiteKey" style="loat: left; width: 100%;"></div>
                </template>
                <div style="clear:both;"></div>
                <div class="finalsubmit" id="finalsubmit">
                    <!--<div class="submitbutton">
                        <button
                            type="submit"
                            v-if="!isReviewClicked"
                            @click="onReviewClicked"
                            class="btn btn-primary"
                        >Review</button>
                        <button
                            type="submit"
                            v-else
                            @click="isReviewClicked = false"
                            class="btn btn-primary"
                        >Back</button>
                    </div>-->
                    <div class="submitbutton">
                        <button
                            type="submit"
                            @click="submitSurvey"
                            class="btn btn-primary"
                        >DONE</button>
                    </div>
                </div>
            </div>
            <div class="quiz-result">
                <p>
                    <strong>{{ total_answered }}</strong>
                    of {{ total_questions }} answered
                </p>
                <div class="progress">
                    <div
                        class="progress-bar progress-bar-striped bg-primary progress-bar-animated"
                        role="progressbar"
                        v-bind:aria-valuenow="progress_percent"
                        :style="'width:'+ progress_percent+'%'"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
            <b-modal size="lg" class="gallery_modal" id="modal1" @cancel="onCancel('main')" ref="myModalRef" hide-footer :hide-header=true>
                <img class="w-100 mb-3" :src="viewImage">
                <div class="clearfix"></div>
                <div class="image-arrows customIconModelFooter clearfix">
                    <button type="button" class="ml-2 btn btn-primary pull-right themebutton" v-on:click="changeImage('next')"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                    <button type="button" class="ml-2 btn btn-primary pull-right themebutton" v-on:click="changeImage('previous')"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    var exten = ['gif', 'jpg', 'jpeg', 'png'];
    import Vue from "vue";
    import { HTTP } from "../http-common.js";
    import { commonMixins } from "../mixins/commonMixins";
    import "vue-good-table/dist/vue-good-table.css";
    import vSelect from "vue-select";
    import axios from 'axios';

    /** Vue2 Dropzone */
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    var self;
    var qDropZone;
    var filesLeft = 1;

    export default {
        name: "Survey",
        mixins: [commonMixins],
        components: {
            "v-select": vSelect,
            vueDropzone: vue2Dropzone,
        },
        data() {
            return {
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/survey/uploadimage",
                    method: "POST",
                    paramName: "file",
                    autoProcessQueue: true,
                    maxFilesize: 20, //MB
                    dictFileTooBig: "File is to big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: "Cancel",
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png, .doc, .docx, .xlsx, .xls, .ppt, .pptx, .pdf",
                    maxFiles: filesLeft,
                    /* headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }, */
                    init: function() {
                        qDropZone = this;
                    }
                },
                selectedStore: {
                    id: 0,
                    name: "",
                    region_id: 0,
                    region_name: ""
                },
                selectedDepartment: {
                    id: 0,
                    name: ""
                },
                surveyOptions: [{}],
                departmentOptions: [{}],
                survey_url: "",
                isReviewClicked: false,
                survey_info: {},
                user_section: [{}],
                user_info: {
                    dayforce_id: "",
                    dayforce_id_error: false,
                    user_name: "",
                    user_name_error: false,
                    user_location: "",
                    user_location_error: false,
                    show_correct_answer_on_survey_submit: false,
                    show_score_on_survey_submit: false
                },
                survey_section: [{}],
                total_questions: 0,
                total_answered: 0,
                progress_percent: 0,
                answered_array: [],
                isSurveySubmitted: false,
                lightboxImages: [],
                viewImage: '',
                index: 0,
                tempQIndex: -1,
                recaptchaVerified: false,
                recaptchaSiteKey: '',
                recaptchaToken: '',
                companyObj : {},
                otherOptionQueAnsIdArray : [],
                errorQuestionArray : [],
                dropzone_qindex: 0,
            };
        },
        filters: {
            numberToChar: function(value) {
                return String.fromCharCode(value + 64);
            }
        },
        beforeMount: function() {
            self = this;
        },
        mounted: function() {
            self.recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
            self.survey_url = self.$route.params.url;
            if (self.survey_url == "") {
                self.showSAlert(
                    "Please enter Valid Survey Url",
                    self.saOpTypes.INFO,
                    function() {}
                );
            } else {
                self.loadSurvey(self.survey_url);
            }
            self.listStore();
            this.$nextTick(function () {
                // document.querySelector('body').setActive();
                grecaptcha.render('recaptcha-main');
            });
        },
        methods: {

            onVerify: function (response) {
                self.recaptchaVerified = true;
                self.recaptchaToken = response;
            },
            onChangeStore: function() {
                if (self.selectedStore != null) {
                    var store_id = self.selectedStore.id;
                }
                //self.listDepartment(store_id);
            },
            listDepartment: function(store_id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        store_id: store_id
                    }
                };

                HTTP.get("department/list", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.departmentOptions = response.data.content.row;
                            self.selectedDepartment.id = self.departmentOptions[0].id;
                            self.selectedDepartment.name = self.departmentOptions[0].name;
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            listStore: function(config) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get("store/list", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.surveyOptions = response.data.content.row;
                            /*console.log('self.surveyOptions');
                            console.log(self.surveyOptions[0].region_name);
                            self.selectedStore.id = self.surveyOptions[0].id;
                            self.selectedStore.name = self.surveyOptions[0].name;
                            self.selectedStore.region_id = self.surveyOptions[0].region_id;
                            self.selectedStore.region_name = self.surveyOptions[0].region_name;
                            self.listDepartment(self.surveyOptions[0].id);*/
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            onReviewClicked: function() {
                if (!this.recaptchaVerified) {
                    alert('Please verify captcha.');
                } else {
                    self.isReviewClicked = true;
                    var errorCount = self.validateSurvey();
                    self.scrollToSurveyBlock(self.errorQuestionArray[0]);

                }
            },
            submitSurvey: function() {
                self.recaptchaVerified = true;
                self.recaptchaToken = grecaptcha.getResponse();
                if (!this.recaptchaVerified) {
                    alert('Please verify captcha.');
                } else {
                    var errorCount = self.validateSurvey();
                    if (errorCount > 0) {
                        self.scrollToSurveyBlock(self.errorQuestionArray[0]);
                        // self.scrollToSurveyBlock(0, self.$refs.container);
                        return;
                    }
                }
                self.isSurveySubmitted = true;
                var randomValue = Math.floor((Math.random() * 1000000));
                var data = {
                    survey_id: self.survey_info.survey_id,
                    /* dayforce_id: self.user_info.dayforce_id,
                    user_name: self.user_info.user_name,
                    store_id: self.selectedStore.id, //self.user_info.user_location,
                    questions: self.survey_section */
                    dayforce_id: '#' + randomValue.toString(),
                    user_name: "User Name",
                    store_id: 1, //self.user_info.user_location,
                    questions: self.survey_section,
                    recaptcha_token: self.recaptchaToken,
                    image_path: self.imagesPaths,
                    image_type: self.imagesType,
                    image_size: self.imagesSize,
                };

                var config = {
                    //headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };

                HTTP.post("survey/submitbyuser", data, config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.isSurveySubmitted = false;
                            if (response.data.content.show_correct_answer_on_survey_submit == 1 && response.data.content.show_score_on_survey_submit == 1) {
                                self.showSAlert(
                                    response.data.content.message,
                                    self.saOpTypes.SUCCESS,
                                    ''
                                );
                                self.answerHighlighted(response);
                            } else if (response.data.content.show_correct_answer_on_survey_submit == 1) {
                                self.answerHighlighted(response);
                            } else if (response.data.content.show_score_on_survey_submit == 1) {
                                /* self.showSAlert(
                                    response.data.content.message,
                                    self.saOpTypes.SUCCESS,
                                    function() {
                                        self.$router.push("/survey/submit");
                                    }
                                ); */
                                self.$router.push("/survey/submit");
                            } else {
                                /* self.showSAlert(
                                    response.data.content.message,
                                    self.saOpTypes.SUCCESS,
                                    function() {
                                        self.$router.push("/survey/submit");
                                    }
                                ); */
                                self.$router.push("/survey/submit");
                            }
                        }
                        if (response.data.code == 201) {
                            self.hideLoader();
                            self.showSAlert(response.data.message, self.saOpTypes.INFO, "");
                        }
                    })
                    .catch(function(err) {
                        self.isSurveySubmitted = false;
                        self.catchHandler(err, function(params) {
                            console.log(params);
                        });
                    });
            },
            answerHighlighted: function(response) {
                response.data.content.result.questions.forEach(function(row) {
                    if(row.question_type == "single") {
                        $(".quiz-radio-container :input").each(function() {
                            if($(this).val() ===  row.correct_answers) {
                                $(this).parent().find('label').css('background-color', 'white');
                            }
                        });
                    } else {
                        $(".quiz-checkbox-container :input").each(function() {
                            if(row.correct_answers.includes($(this).val())) {
                                $(this).parent().find('label').css('background-color', 'white');
                            }
                        });
                    }
                });
            },
            loadSurvey: function(survey_url) {
                var config = {
                    params: {
                        url: survey_url
                    }
                };

                HTTP.get("survey/fetchbyurl", config)
                    .then(function(response) {
                        if(response.data.code == 200) {
                            var questionNoteCount = 0;
                            for (let i = 0; i < response.data.content.survey_section.length; i++) {
                                if (response.data.content.survey_section[i].other_option == 1) {
                                    let object = {};
                                    object.question_id = response.data.content.survey_section[i].question_id;
                                    object.other_option_answer_id = response.data.content.survey_section[i].other_option_answer_id;
                                    self.otherOptionQueAnsIdArray.push(object);
                                }
                                if(response.data.content.survey_section[i].question_type == 'note') {
                                    questionNoteCount++;
                                }

                            }
                            self.survey_info = response.data.content.survey_info;
                            self.user_section = response.data.content.user_section;
                            self.survey_section = response.data.content.survey_section;
                            self.companyObj = response.data.content.company;
                            self.total_questions = self.survey_section.length - questionNoteCount;
                            document.title = self.survey_info.name;

                            const surveyData = {
                                survey_id: self.survey_section[0].survey_id,
                                custom_end_message: self.survey_section[0].custom_end_message,
                                show_back_to_survey_btn: self.survey_section[0].show_back_to_survey_btn
                            };

                            localStorage.setItem('survey_data', JSON.stringify(surveyData));
                        } else {
                            self.showSAlert(
                                response.data.status,
                                self.saOpTypes.INFO,
                                function() {
                                    self.$router.push("/");
                                }
                            );
                        }
                    })
                    .catch(function(err) {})
                    .finally(function() {});
            },
            validateSurvey: function() {
                var errorCount = 0;

                /* if (self.user_info.user_name.length == 0) {
                    self.user_info.user_name_error = true;
                    errorCount++;
                } else {
                    self.user_info.user_name_error = false;
                }
                if (self.selectedStore.id == 0) {
                    self.user_info.user_location_error = true;
                    errorCount++;
                } else {
                    self.user_info.user_location_error = false;
                }
                if (self.user_info.dayforce_id.length == 0) {
                    self.user_info.dayforce_id_error = true;
                    errorCount++;
                } else {
                    self.user_info.dayforce_id_error = false;
                } */
                self.errorQuestionArray = [];
                // console.log(JSON.stringify(self.survey_section));
                for (var i = 0; i < self.survey_section.length; i++) {
                    var questionObject = self.survey_section[i];
                    if (questionObject.is_required) {
                        if(questionObject.question_type == 'free' || questionObject.question_type == 'attachment') {
                            questionObject.show_error = false;
                            if(questionObject.selected_answer == '') {
                                errorCount++;
                                questionObject.show_error = true;
                                self.errorQuestionArray.push(questionObject.question_id);
                            }
                        } else {
                            var isAnswered = self.answered_array.includes(
                                questionObject.question_id
                            );
                            if (isAnswered) {
                                questionObject.show_error = false;
                            } else {
                                errorCount++;
                                questionObject.show_error = true;
                                self.errorQuestionArray.push(questionObject.question_id);
                            }
                        }
                    }
                    // Very Important as it helps to update current Object in the Array.
                    self.$set(self.survey_section, i, questionObject)
                }
                return errorCount;
            },
            hideShowOtherOptionTextAreaForMultipleSelection(questionObject) {
                let tempArray = [];
                for (let i = 0; i < questionObject.answers.length; i++) {
                    $('#question-' + questionObject.answers[i].question_id).css('display', 'none');
                }
                for (let i = 0; i < questionObject.selected_answer.length; i++) {
                    for (let j = 0; j < self.otherOptionQueAnsIdArray.length; j++) {
                        if (questionObject.selected_answer[i] === self.otherOptionQueAnsIdArray[j].other_option_answer_id) {
                            tempArray.push(self.otherOptionQueAnsIdArray[j]);
                        }
                    }
                }
                if (tempArray.length > 0) {
                    for (let x = 0; x < tempArray.length; x++) {
                        if (questionObject.selected_answer.includes(tempArray[x].other_option_answer_id)) {
                            let object = {};
                            object.id = tempArray[x].id;
                            object.answer = tempArray[x].answer;
                            object.question_id = tempArray[x].question_id;
                            $('#question-' + tempArray[x].question_id).css('display', 'block');
                        }
                    }
                }
            },
            hideShowOtherOptionTextAreaForSingleSelection : function (questionObject) {
                let tempArray = [];
                for (let i = 0; i < questionObject.answers.length; i++) {
                    $('#question-' + questionObject.answers[i].question_id).css('display', 'none');
                }
                for (let j = 0; j < self.otherOptionQueAnsIdArray.length; j++) {
                    if (questionObject.selected_answer === self.otherOptionQueAnsIdArray[j].other_option_answer_id) {
                        tempArray.push(self.otherOptionQueAnsIdArray[j]);
                    }
                }

                if (tempArray.length > 0) {
                    if (tempArray[0].other_option_answer_id == questionObject.selected_answer) {
                        let object = {};
                        object.id = tempArray[0].id;
                        object.answer = tempArray[0].answer;
                        object.question_id = tempArray[0].question_id;
                        $('#question-' + tempArray[0].question_id).css('display', 'block');
                    }
                }

            },
            /**
             * Function to calculate self.progress_percent value based on Total Questions answered
             * Checks where the Question is Multiple or Single.
             * If 'multiple', first checks if (questionObject.selected_answer.length and answered_array to check if <b>questionObject.question_id</b> exists.
             *    If <b>questionObject.question_id</b> doesn't exist add it to the answered_array list
             *    If <b>questionObject.question_id</b> exist then get list of questionObject.selected_answer and loop through array to check if ans_id exists in selectedAnswers
             *       depending on ans_id existence in selectedAnswers, update self.total_answered and self.answered_array
             * If 'single' adds <b>questionObject.question_id</b> to the answered_array list
             * Also updating questionObject.show_error when unanswered questionObject.is_required is answered
             */
            totalAnswered: function(q_index, ans_id) {
                var questionObject = self.survey_section[q_index];
                if (questionObject.question_type == "multiple") {
                    self.hideShowOtherOptionTextAreaForMultipleSelection(questionObject);
                    if (
                    questionObject.selected_answer.length > 0 &&
                    !self.answered_array.includes(questionObject.question_id)
                    ) {
                        self.total_answered++;
                        self.answered_array.push(questionObject.question_id);
                        if (questionObject.show_error) {
                            questionObject.show_error = false;
                        }
                    } else {
                        var isAnsInArray = false;
                        var selectedAnswers = questionObject.selected_answer;
                        for (var j = 0; j < selectedAnswers.length; j++) {
                            if (ans_id == selectedAnswers[j]) {
                                isAnsInArray = true;
                                break;
                            }
                        }

                        if (!isAnsInArray) {
                            if (questionObject.selected_answer.length == 0) {
                                self.total_answered--;
                                self.answered_array.pop(questionObject.question_id);
                            }
                        }
                    }
                } else if (questionObject.question_type == "single") {
                    self.hideShowOtherOptionTextAreaForSingleSelection(questionObject);
                    //self.scrollToSurveyBlock(q_index + 1);
                    if (
                    typeof questionObject.selected_answer != "undefined" &&
                    !self.answered_array.includes(questionObject.question_id)
                    ) {
                        self.total_answered++;
                        self.answered_array.push(questionObject.question_id);
                        if (questionObject.show_error) {
                            questionObject.show_error = false;
                        }
                    }
                } else if (questionObject.question_type == "free" || questionObject.question_type == 'attachment') {
                    if(self.tempQIndex != q_index) {
                        self.tempQIndex = q_index;
                        self.answered_array.push(questionObject.question_id);
                        self.total_answered++;
                    }
                }

                self.total_answered = self.answered_array.length;
                if (self.total_answered > 0)
                    self.progress_percent =
                    (self.total_answered * 100) / self.total_questions;
                else self.progress_percent = 0;
            },
            userInfoChanged: function() {
                if (self.user_info.user_name.length == 0) {
                    self.user_info.user_name_error = true;
                } else {
                    self.user_info.user_name_error = false;
                }
                if (self.selectedStore.id == 0) {
                    self.user_info.user_location_error = true;
                } else {
                    self.user_info.user_location_error = false;
                }
                if (self.user_info.dayforce_id.length == 0) {
                    self.user_info.dayforce_id_error = true;
                } else {
                    self.user_info.dayforce_id_error = false;
                }
            },
            /**
             * Function to Scroll to desired q_index (Question Index) or element (Elememt)
             */
            scrollToSurveyBlock: function(q_index, element = null) {
                // Uncomment the following for Manually calculating offset.
                // self.scrollToOptions.offset = $("#quizblock"+q_index).offset().top - ( $(window).height() - $("#quizblock"+q_index).outerHeight(true) ) / 2
                if (element != null) {
                    self.$scrollTo(element, 1500);
                    //, self.scrollToOptions);
                }
                else {
                    self.$scrollTo("#quizblock" + q_index, 1500); //, self.scrollToOptions);
                }
            },
            /* Get images for gallery view */
            openGallery: function (documents, index) {
                var self = this;
                var arr = [];
                var new_index = 0;
                $.each(documents, function (i) {
                    if (jQuery.inArray(documents[i].extension.toLowerCase(), exten) !== -1) {
                        if (i == index) {
                            index = new_index;
                        }
                        arr.push(documents[i].image_path);
                        new_index = new_index + 1;
                    }
                });
                self.loadImagesInLightBox(arr, index);
            },
            loadImagesInLightBox: function (arr, index) {
                var self = this;
                self.lightboxImages = arr;
                self.index = index;
                self.viewImage = self.lightboxImages[self.index];
                self.$refs.myModalRef.show();
            },
            changeImage: function (move) {
                var self = this;
                if (move == 'next') {
                    self.index = self.index + 1;
                    if (self.lightboxImages.length <= self.index) {
                        self.index = 0;
                    }
                } else {
                    self.index = self.index - 1;
                    if (self.index < 0) {
                        self.index = (self.lightboxImages.length - 1);
                    }
                }
                self.viewImage = self.lightboxImages[self.index];
            },
            /** DropZone */
            sendingEvent (file, xhr, formData) {
                formData.append('question_id', this.dropzone_qindex);
            },
            uploadDocument: function(file, response) {
                var jsonResponse = JSON.parse(response);
                /* console.log('Here..');
                console.log(jsonResponse.content.result); */
                self.imagesPaths.push(jsonResponse.content.result);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);

                self.survey_section[jsonResponse.content.question_id].show_error = false;
                self.survey_section[jsonResponse.content.question_id].selected_answer = jsonResponse.content.result;
                self.totalAnswered(jsonResponse.content.question_id, -1);

                // To Do Uncomment the following to fix Max Files error in Vue Dropzone.
                // self.$refs.dropzone.setOption('maxFiles', 4);
            },
            removedSelectedFile: function(file, xhr, error) {
                var removeFile = "";
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    //self.$refs.dropzone.setOption('maxFiles', 4);
                } else {
                    if (typeof file.xhr != "undefined") {
                        var filePath = JSON.parse(file.xhr.response);
                        removeFile = filePath.content;
                        //console.log(removeFile);return false;
                        self.removeFileFromServer(removeFile);
                    }
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                //self.$refs.dropzone.setOption('maxFiles', 4);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function(filePath) {
                var config = {
                    /* headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }, */
                    data: {
                        filePath: filePath
                    }
                };
                HTTP.delete("survey/removeimage", config)
                    .then(function(response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function() {});
                    });
            },
            fileError: function(file, xhr, error) {},
            // fileAdd: function(file, xhr, error) {
            fileAdd: function(qIndex, tempIndex, file) {
                console.log(qDropZone.options.maxFiles);
                self.dropzone_qindex = qIndex;

                /*if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', 4);
                }*/
            },
            manuallyAddFile: function(file) {
                var type = "image/" + file.extension;
                var fileMeta = {
                    size: file.size,
                    name: file.name,
                    type: type
                };
                self.imagesPaths.push(file.image_path);
                self.imagesType.push(type);
                self.imagesSize.push(file.size);
                self.$refs.dropzone.manuallyAddFile(fileMeta, file.image_full_path);
            },
            progressOfUload(totaluploadprogress, totalBytes, totalBytesSent) {
                if (totaluploadprogress == 100) {
                    self.isFileUploading = false;
                } else {
                    self.isFileUploading = true;
                }
            },
            removeAllFiles: function() {
                self.$refs.dropzone.removeAllFiles();
            }
            /** DropZone */
        }
    };
</script>
