<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div class="page-name quiz-page-name">
                    {{ pageTitle }} 
                    <button id="exportAnsweredSurvey" type="submit" v-on:click.prevent="addUser" class="btn btn-primary waves-effect pull-right">Add</button>
                </div>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <template>
                    <vue-good-table
                        ref="listUser"
                        :columns="columns"
                        :rows="rows"
                        mode="remote"
                        :search-options="{
                            enabled: false
                        }"
                        @on-page-change="onPageChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        :totalRows="totalRecords"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            pageLabel: 'Page',
                            position: 'bottom',
                            perPage: serverParams.perPage,
                            perPageDropdown: [25, 50, 100, 200 ,500],
                            setCurrentPage: serverParams.setCurrentPage,
                            dropdownAllowAll: false,
                        }"
                    >
                        <!-- Custome Pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="vgt-wrap__footer crm">
                                <div  class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                        <v-select title="Select Per Page"
                                            v-model="serverParams.perPage"
                                            @change="changedValueTask"
                                            @input="changedValueTaskCustomPerPageTask()"
                                            name="customPerPage"
                                            id="customPerPage"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="serverParams.perPageDropdown"
                                            placeholder="Select Per Page">
                                        </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                        <p class="pageRowCount">{{paginationDisplayRowsTask}} of {{totalRecords}}</p>
                                    </div>
                                    <div class="col-sm-12 col-md-6 Pagination">
                                        <ul class="pagination">
                                            <li class="FirstPage" title="First Page">
                                                <template v-if="Number(serverParams.page) == 1">
                                                    <a class="page-link" href="javascript:void(0)">1</a>
                                                </template>
                                                <template v-else>
                                                    <a v-on:click="onPerticularPageClickTask(1)" class="page-link" href="javascript:void(0)">1</a>
                                                </template>
                                            </li>
                                            <li class="page-item PreviousPage" title="Previous Page">
                                                <template v-if="Number(serverParams.page) == 1">
                                                    <a class="page-link" href="javascript:void(0)" tabindex="-1"><i class="fa fa-angle-left"></i></a>
                                                </template>
                                                <template v-else>
                                                    <a class="page-link" href="javascript:void(0)" tabindex="-1" v-on:click="onPerticularPageClickTask(Number(serverParams.page)-1)" ><i class="fa fa-angle-left"></i></a>
                                                </template>
                                            </li>
                                            <template v-if="pageNumberListTask() < displayPageTask">
                                                <template v-for="(n, index) in pageNumberListTask()">
                                                    <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                        <a class="page-link" href="javascript:void(0)">
                                                            {{n}}
                                                        </a>
                                                    </li>
                                                        <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                        <a class="page-link" href="javascript:void(0)">
                                                            {{n}}
                                                        </a>
                                                    </li>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <!--End New Implementation-->
                                                <template v-if="Number(serverParams.page) <= pageCompareTask">
                                                    <template v-for="(n, index) in displayPageTask">
                                                        <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                            <a class="page-link" href="javascript:void(0)">
                                                                {{n}}
                                                            </a>
                                                        </li>
                                                        <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                            <a class="page-link" href="javascript:void(0)">
                                                                {{n}}
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                                <template v-else-if="Number(serverParams.page) >= (pageNumberListTask() - (pageCompareTask-1))">
                                                    <template v-for="(n, index) in pageNumberListTask()">
                                                        <template v-if="n > (pageNumberListTask() - displayPageTask)">
                                                        <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                            <a class="page-link" href="javascript:void(0)">
                                                            {{n}}
                                                            </a>
                                                        </li>
                                                        <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                            <a class="page-link" href="javascript:void(0)">
                                                            {{n}}
                                                            </a>
                                                        </li>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-for="(n, index) in pageNumberListTask()" v-if="(n >= (Number(serverParams.page) - (pageCompareTask -1))) && (n <= (Number(serverParams.page) + (pageCompareTask -1)))">
                                                        <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                            <a class="page-link" href="javascript:void(0)">
                                                                {{n}}
                                                            </a>
                                                        </li>
                                                        <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                            <a class="page-link" href="javascript:void(0)">
                                                                {{n}}
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                            </template>
                                            <li class="page-item NextPage" title="Next Page">
                                                <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                                    <a class="page-link" href="javascript:void(0)"><i class="fa fa-angle-right"></i></a>
                                                </template>
                                                <template v-else>
                                                    <a class="page-link" href="javascript:void(0)" v-on:click="onPerticularPageClickTask(Number(serverParams.page)+1)"><i class="fa fa-angle-right"></i></a>
                                                </template>
                                            </li>
                                            <li class="LastPage" title="Last Page">
                                                <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                                    <a class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                                </template>
                                                <template v-else>
                                                    <a v-on:click="onPerticularPageClickTask(pageNumberListTask())" class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                                </template>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mt-2 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="mt-1 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                        <input @keyup.enter="jumpToPageInTask" v-model="jumpToPageTask" type="text" class="form-control form-control-sm" name="jumpToPageTask" id="jumpToPageTask" placeholder="Page">
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- End Custome Pagination -->
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'action'">
                                <b-button class="myButton" @click="updateUser(props.row.id)">
                                    <i class="fa fa-pencil" style="font-size:18px;color:black"></i>
                                </b-button>
                            </span>
                            <span v-else>{{props.formattedRow[props.column.field]}}</span>
                        </template>
                    </vue-good-table>
                </template>
            </div>
            <!--page content-->
        </div>
        <backend-footer></backend-footer>
    </div> 
    <!-- Page Content Wrapper --> 
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import { commonMixins } from "../../../mixins/commonMixins";
    import BackendFooter from "@/components/backend/partials/footer";
    import { VueGoodTable } from "vue-good-table";
    import "vue-good-table/dist/vue-good-table.css";
    import vSelect from "vue-select";
    import EventBus from "../../event-bus";
    Vue.use(VueGoodTable);
    var self;

    export default {
        name: "ListUser",
        components: {
            "backend-footer": BackendFooter,
            "vue-good-table": VueGoodTable,
            "v-select": vSelect
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: 'List User',
                columns: [
                    {
                        label: "First Name",
                        field: "first_name",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "First Name", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Last Name",
                        field: "last_name",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Last Name", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Email",
                        field: "email",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Email", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Dayforce Id",
                        field: "dayforce_id",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Dayforce Id", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Action",
                        field: "action",
                        sortable: false,
                        thClass: "disable-sorting"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {
                        question: "",
                        survey: ""
                    },
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    page: 1,
                    perPage: 25,
                    setCurrentPage: 1,
                    perPageDropdown : [25, 50, 100, 200, 500],
                },
                displayPageTask: 7,
                pageCompareTask: 0,
                paginationDisplayRowsTask: '',
                jumpToPageTask: 0,
            }
        },
        beforeMount: function() {
            self = this;
            EventBus.$emit("menu-selection-changed", "listuser");
        },
        mounted: function() {
            this.jumpToPageTask = this.serverParams.page;
            this.pageCompareTask = Math.ceil(this.displayPageTask / 2);
            $("#jumpToPageTask").on('keyup', function (e) {
                if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
                    self.jumpToInputPageTask(self.jumpToPageTask)
                }
            });
            self.loadUser();
        },
        methods: {
            addUser: function() {
                self.$router.push("/backend/user/add");
            },
            updateUser: function(id) {
                self.$router.push("/backend/user/edit/" + id);
            },
            changedValueTask: function (value) {
                /*console.log(value);*/
            },
            jumpToPageInTask: function (e) {
                self.jumpToInputPageTask(self.jumpToPageTask);
            },
            changedValueTaskCustomPerPageTask: function () {
                self.jumpToInputPageTask(self.serverParams.page);
                self.loadUser();
            },
            pageNumberListTask: function () {
                var totalRecord = self.totalRecords;
                var count = 0;
                for (var i = 0; totalRecord > 0; i++) {
                    totalRecord = totalRecord - self.serverParams.perPage;
                    count++;
                }
                return count;
            },
            jumpToInputPageTask: function (pageNumber) {
                var reg = /^\d+$/;
                if (pageNumber <= 0 || pageNumber == '') {
                    pageNumber = 1;
                } else if (pageNumber > self.pageNumberListTask()) {
                    pageNumber = self.pageNumberListTask();
                    if (pageNumber <= 0 || pageNumber == '') {
                        pageNumber = 1;
                    }
                } else if (!String(self.jumpToPageTask).match(reg)) {
                    pageNumber = 1;
                }
                self.onPerticularPageClickTask(pageNumber);
            },
            onPerticularPageClickTask: function (pageNumber) {
                self.serverParams.page = pageNumber;
                self.jumpToPageTask = pageNumber;
                self.loadUser();
            },
            onPageChange(params) {
                self.updateParams({ page: params.currentPage });
                self.loadUser();
            },
            updateParams(newProps) {
                self.serverParams = Object.assign({}, self.serverParams, newProps);
            },
            onPerPageChange(params) {
                self.updateParams({ perPage: params.currentPerPage });
                self.totalRecords = self.totalRecords;
                self.loadUser();
            },
            onColumnFilter(params) {
                self.isLoading = false;
                self.updateParams(params);
                {
                    field: "question";
                    field: "survey";
                }

                this.serverParams.page = 1;
                var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
                var filters = filtersPr.columnFilters;

                if (self.firstLoad == 1 || filters.question || filters.section) {
                    this.loadUser();
                }
            },
            onSortChange(params) {
                //console.log(params);
                this.updateParams({
                    sort: {
                        type: params[0].type,
                        field: params[0].field
                    }
                });
                this.loadUser();
            },
            loadUser: function() {
                self.firstLoad = 1;
                var newPage = Math.ceil(self.totalRecords / self.serverParams.perPage);
                if (newPage) {
                    if (newPage < self.serverParams.page) {
                        self.serverParams.page = newPage;
                    }
                }
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var fields = {
                    page: self.serverParams.page,
                    perPage: self.serverParams.perPage,
                    filters: self.serverParams.columnFilters,
                    messageData: self.message,
                    sort: self.serverParams.sort
                };
                HTTP.post("/user/list", fields, config)
                    .then(function(response) {
                        if (response.data.code == "200") {
                            self.rows = response.data.content.row;
                            self.totalRecords = response.data.content.totalRecords;
                        }
                        self.paginationDisplayRowsTask = self.getRecords(self.serverParams.page, self.serverParams.perPage, self.totalRecords);
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function () {});
                    });
            },
        }
    }
</script>