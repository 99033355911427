<template>
    <!-- Sidebar -->
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <li v-bind:class="{'active': menu.isActive}" v-for="(menu, m_index) in menuArray" :id="menu.id" :ref="menu.ref" v-bind:key="'menu-'+m_index">
                <router-link v-bind:to="menu.routerlink"><span class="fa-stack fa-lg pull-left"><i :class="menu.icon"></i></span>{{ menu.name }}</router-link>
            </li>
        </ul>
    </div>
    <!-- Sidebar -->
</template>

<script>

    var self;

    import Vue from 'vue';
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";

    export default {
        name: 'Center',
        components: {

        },
        data() {
            return {
                menuArray: [{
                    name: "Survey",
                    isActive: false,
                    ref: 'listsurvey',
                    id: 'list-survey',
                    routerlink: {
                        name: 'ListSurvey'
                    },
                    icon: 'fa fa-file-o fa-stack-1x'
                },{
                    name: "Question",
                    isActive: false,
                    ref: 'listquestion',
                    id: 'list-question',
                    routerlink: {
                        name: 'DraggableQuestionList'
                    },
                    icon: 'fa fa-question fa-stack-1x'
                },{
                    name: "Answered Survey",
                    isActive: false,
                    ref: 'listansweredwurvey',
                    id: 'list-answered-survey',
                    routerlink: {
                        name: 'ListAnsweredSurvey'
                    },
                    icon: 'fa fa-plus fa-stack-1x'
                },{
                    name: "Users",
                    isActive: false,
                    ref: 'listuser',
                    id: 'list-user',
                    routerlink: {
                        name: 'ListUser'
                    },
                    icon: 'fa fa-user fa-stack-1x'
                },
                    {
                        name: "Company",
                        isActive: false,
                        ref: 'listcompany',
                        id: 'listcompany',
                        routerlink: {
                            name: 'ListLogo'
                        },
                        icon: 'fa fa-briefcase fa-stack-1x'
                    }]
            }

        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {


            /*switch(this.$route.path) {
                case "/backend/listquestion":
                    $("#menu li").removeClass("active");
                    $("#list-question").addClass('active')
                    break;
                case "/backend/listsurvey":
                    $("#menu li").removeClass("active");
                    $("#list-quiz").addClass('active')
                    break;
                case "/backend":
                    $("#menu li").removeClass("active");
                    $("#list-quiz").addClass('active')
                    self.$router.push("/backend/listsurvey");
                    break;
            }*/

            $("#menu-toggle").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled");
            });
            $("#menu-toggle-2").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled-2");
                $('#menu ul').hide();
            });

            $('.tree-toggle').click(function () {
                $(this).parent().children('ul.tree').toggle(200);
            });

            $('#menu ul').hide();
            //$('#menu ul').children('.current').parent().show();
            //$('#homeLink').addClass('active');

           /*
            $('#menu').on("click", "li > a", function(event) {
              $("#menu li").removeClass("active");
              $(this).parent().addClass('active');
              $(this).addClass('active');
              $(this).parent().parent().parent().addClass('active');
            });*/

            $('#menu').on("click", "li > label", function(event) {
              if(!$(this).parent().children('ul.tree').is(':visible')) {
                  $(this).children('.arrow-handle').removeClass('fa-angle-down');
                  $(this).children('.arrow-handle').addClass('fa-angle-up');
              } else {
                  $(this).children('.arrow-handle').removeClass('fa-angle-up');
                  $(this).children('.arrow-handle').addClass('fa-angle-down');
              }
              $(this).parent().children('ul.tree').toggle(200);
            });

        },
        methods: {
        }
    }

    EventBus.$on('menu-selection-changed', function (menuref) {

        if(typeof (self) != "undefined") {
            for(var i = 0; i < self.menuArray.length; i++ ) {
                var menuObject = self.menuArray[i];
                if(menuref == menuObject.ref) {
                    menuObject.isActive = true;
                } else {
                    menuObject.isActive = false;
                }
            }
            //console.log(self.$refs[menuref][0].classList);
            //self.$refs[menuref][0].classList.remove('active');
        }
    });
</script>

