<template>
    <div class="admin-footer">
        <div class="loading-container" style="display: none;">
            <div class="loading">
                <img src="/static/images/loading.gif">
            </div>
        </div>
        © {{currentYear}}<!--2018--> Velocity Vehicle Group. All Rights Reserved. 
    </div>
</template>
<script>
    import Vue from 'vue';
    export default {
        name: 'Footer',
        components: {

        },
        data() {
            return {
                error: '',
                currentYear: '2018',
            }

        },
        beforeMount: function () {

        },
        mounted: function () {
            var self = this;
            self.currentYear = new Date().getFullYear();
            self.closeMenuMobile();
        },
        methods: {
            closeMenuMobile() {
                $("html, body").animate({scrollTop: 0}, 10);
                if ($(window).width() < 1100) {
                    $(".app-sidebar-right").removeClass("toggled");
                    $(".app-sidebar-right").removeClass("toggled-2");
                }
            },
        }
    }
</script>
