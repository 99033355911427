<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="message-info" v-show="!isEditingAllowed">
                <div class="message-info-block">Editing is disabled for this Survey as it is already Attempted by User(s).</div>
            </div>
            <div class="page-header-top-container">
                <div class="page-name">{{ pageTitle }}</div>
                <button type="submit" v-on:click.prevent="addQuestion" class="btn btn-primary waves-effect pull-right" :disabled="!isEditingAllowed">Add</button>
                <button id='exportQuestions' type="submit" v-on:click.prevent="exportQuestion" class="btn btn-primary waves-effect pull-right">Export</button>
            </div>

            <div class="admin-page-content">
                <div class="col-12 forms-block SelectQuizBlock">
                    <div class="col-5 paddingleft0">
                        <div class="form-label">Select Survey :</div>
                    </div>
                    <div class="col-5 paddingleft0">
                        <v-select
                                v-model="selectedSurvey"
                                name="survey"
                                placeholder="Select Survey"
                                id="selectedSurvey"
                                @input="onChangeSurvey()"
                                :options="surveyOptions"
                                :filter-by="surveyFilter"
                                label="unique_code"
                        >
                            <template slot="no-options">Type Survey here</template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">{{ option.name }} {{ option.unique_code }}</div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">{{ option.name }} {{ option.unique_code }}</div>
                            </template>
                        </v-select>
                    </div>
                </div>
                <!--Forms-->
                <div class="forms-container">
                    <div class="row no-margin">
                        <template>
                            <draggable ref="draggableRef" class="draggable-div" v-model="questionList" :options="dragOptions" @start="drag=true" @change="changePosition()">
                                <div v-for="(element, index) in questionList" :id="'menu-'+element.id" :key="'menu-'+element.id" class="edit-menu">
                                    <img class="drag-handle" src="/static/images/app-bar-icon.png">
                                    <p>{{ element.question }}</p>
                                    <div class="menu-actions">
                                        <router-link v-show="isEditingAllowed" title='Edit FAQ' v-bind:to="{name: 'EditQuestion',params:{id:element.id}}">
                                            <img src="/static/images/edit-icon.png">
                                        </router-link>
                                        <a v-show="isEditingAllowed" href="javascript:void(0)" id="delete-menu" v-on:click="deleteQuestion(element.id)"><img src="/static/images/delete-icon.png"></a>
                                    </div>
                                </div>
                            </draggable>
                        </template>
                    </div>
                </div>
                <!-- Forms -->
            </div>
        </div>
        <backend-footer></backend-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import { commonMixins } from "../../../mixins/commonMixins";
    import BackendFooter from "@/components/backend/partials/footer";
    import { VueGoodTable } from "vue-good-table";
    import "vue-good-table/dist/vue-good-table.css";
    import vSelect from "vue-select";
    import EventBus from "../../event-bus";
    import draggable from 'vuedraggable';
    Vue.use(VueGoodTable);
    var self;

    export default {
        name: "QuestionList",
        components: {
            draggable,
            "backend-footer": BackendFooter,
            "vue-good-table": VueGoodTable,
            "v-select": vSelect
        },
        mixins: [commonMixins],
        data() {
            return {
                dragOptions : {
                    group: 'menu',
                    handle: ".drag-handle",
                    disabled : false,
                },
                pageTitle: "Question List",
                selectedSurvey: {
                    id: 0,
                    name: ""
                },
                surveyOptions: [{}],
                questionList : [],
                rows: [],
                totalRecords: 0,
                isEditingAllowed: true,
            };
        },
        beforeMount: function() {
            self = this;
            EventBus.$emit("menu-selection-changed", "listquestion");
        },
        mounted: function() {
            let listSurveyLocalStorage = JSON.parse(localStorage.getItem('listQuestionSelectedSurvey'));
            if (listSurveyLocalStorage != null && listSurveyLocalStorage !== "" && typeof listSurveyLocalStorage !== 'undefined') {
                self.selectedSurvey = {id : listSurveyLocalStorage.id, name : listSurveyLocalStorage.name};
            }
            self.listSurvey();
        },
        methods: {
            onChange: function (event) {
                var self = this;
            },
            changePosition() {
                var fields = {
                    questionList: self.questionList,
                };
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                HTTP.post("/question/changeposition", fields, config)
                    .then(function (response) {
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                //EventBus.$emit("logged-in", true, "", "");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            deleteQuestion(params) {
                var id = params.id;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };

                HTTP.delete("question/delete", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.listSurvey();
                            self.showSAlert(
                                response.data.content.message,
                                self.saOpTypes.SUCCESS,
                                function() {}
                            );
                        }
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function() {});
                    });
            },
            listSurvey: function(config) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.get("survey/list", config)
                    .then(function(response) {
                        if (
                            response.data.code == 200 &&
                            response.data.content.row.data.length > 0
                        ) {
                            self.surveyOptions = response.data.content.row.data;
                            if(self.selectedSurvey.id == 0) {
                                self.selectedSurvey.id = self.surveyOptions[0].id;
                                self.selectedSurvey.name = self.surveyOptions[0].name;
                            }

                            self.checkIfSurveyAttempted(self.selectedSurvey.id, function(result) {
                                if (result) {
                                    self.isEditingAllowed = false;
                                } else {
                                    self.isEditingAllowed = true;
                                }
                                self.dragOptions.disabled = !self.isEditingAllowed;
                                self.listQuestion(self.selectedSurvey.id);
                            });
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            addQuestion: function() {
                self.$router.push("/backend/addquestion/" + self.selectedSurvey.id);
            },
            deleteQuestion(params) {
                var id = params;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };

                HTTP.delete("question/delete", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.listSurvey();
                            self.showSAlert(
                                response.data.content.message,
                                self.saOpTypes.SUCCESS,
                                function() {}
                            );
                        }
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function() {});
                    });
            },
            listQuestion: function(survey_id) {
                self.firstLoad = 1;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        survey_id : self.selectedSurvey.id
                    }
                };
                self.showLoader();
                HTTP.get("question/draggablelist", config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            self.questionList = response.data.content.row;
                            self.rows = response.data.content.row;
                            self.totalRecords = response.data.content.total_records;
                        }
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        self.catchHandler(err, function() {});
                    });
            },
            exportQuestion: function() {
                $('#exportQuestions').text('Exporting...');
                $('#exportQuestions').prop("disabled", true);
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: self.selectedSurvey.id,
                    }
                };
                self.showLoader();
                HTTP.get("question/export", config)
                    .then(function(response) {
                        $('#exportQuestions').text('Export');
                        $('#exportQuestions').prop("disabled", false);
                        if (response.data.code == 200) {
                            // location.href = response.data.content.web;
                            location.href = response.data.content;
                            self.rows = response.data.content.row;
                            self.totalRecords = response.data.content.total_records;
                        }
                        self.hideLoader();
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        $('#exportQuestions').text('Export');
                        $('#exportQuestions').prop("disabled", false);
                        self.catchHandler(err, function() {});
                    });
            },

            onChangeSurvey: function() {
                if (self.selectedSurvey != null) {
                    let surveyId = self.selectedSurvey.id;
                    let surveyName = self.selectedSurvey.name;
                    let surveyObj = {id : surveyId, name : surveyName};
                    localStorage.setItem("listQuestionSelectedSurvey", JSON.stringify(surveyObj));
                    var survey_id = self.selectedSurvey.id;
                    self.checkIfSurveyAttempted(survey_id, function(result) {
                        if (result) {
                            self.isEditingAllowed = false;
                        } else {
                            self.isEditingAllowed = true;
                        }
                        self.dragOptions.disabled = !self.isEditingAllowed;
                        self.listQuestion(survey_id);
                    });
                }
            },
            surveyFilter: function(option, label, search) {
                let temp = search.toLowerCase();
                return (
                    option.name.toLowerCase().indexOf(temp) > -1 ||
                    option.unique_code.toLowerCase().indexOf(temp) > -1
                );
            }
        }
    };
</script>

<style>
</style>
