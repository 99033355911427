import EventBus from "../components/event-bus";
import {HTTP} from '../http-common';
import moment from 'moment';

export const commonMixins = {

    data: () => ({
        colors: ['#ffbb44', '#098de1', '#154174', '#c3202b', '#607d8b', '#444444', '#446626', '#cc9636', '#23727b'],
        menuData: [
            {

            }
        ],
        features: [
            {

            }
        ],
        menuslug: "",
        pageType: "",
        currentTab: "",
        menuParentSlug: "",
        systemYear: "2019",
        locationOptions:[{

        }],
        userOptions:[{

        }],
        // Declaring Sweet Alert Option Types as Constant in mixin for universal access accross Application.
        saOpTypes: {
            SUCCESS: 'success',
            ERROR: 'error',
            WARNING: 'warning',
            INFO: 'info',
            QUESTION: 'question',
        },
        commonErrorMessages: {
            ERROR1: "There was an error, please try again!!!",
        }
    }),
    mounted: function () {
        //console.log("Mounted");
        //console.log(this.$refs);
    },
    created() {
        /*console.log("mixin created");*/
    },
    beforeMount() {

    },
    methods: {
        loadRole : function () {
            let self = this;
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
            };
            HTTP.get('role', config)
                .then(function (response) {
                    // console.log(response);
                    if (response.data.code == '200') {
                        self.roleOptions= response.data.content;
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 0) {
                        self.error = "Remote server can not be reachable";
                    } else {
                        //redirect to login page if user not authenticated
                        if (err.response.status == 401) {
                            //clear previous data
                            localStorage.removeItem("user_obj");
                            localStorage.removeItem("api_token");
                            localStorage.removeItem("profile_image");
                            //EventBus.$emit("logged-in", true, "", "");
                            self.$router.push("/login");
                        }
                        self.error = err.response.data.message;
                    }
                })
        },
        loadUserDetails: function (userId) {
                
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
            };
            var data = {
                user_id: userId,
            };
            HTTP.post("/user/getuserdetails/" + userId, data, config)
                .then(function (response) {
                    if(response.data.code == 200) {
                        window.localStorage.setItem("user_obj", JSON.stringify(response.data.content));
                        var userObj = JSON.parse(localStorage.getItem("user_obj"));
                        if (userObj != null) {
                            EventBus.$emit('login-user-info', userObj.username, userObj.first_name, userObj.last_name, userObj.location_name, userObj.will_call_location_name);
                        }
                    } 
                })
                .catch(function (err) {
                    console.log(err);
                    if (err.response.status == 0) {
                        self.error = "Remote server can not be reachable";
                    } else {
                        //redirect to login page if user not authenticated
                        if (err.response.status == 401) {
                            //clear previous data
                            localStorage.removeItem("user_obj");
                            localStorage.removeItem("api_token");
                            localStorage.removeItem("profile_image");
                            self.$router.push("/login");
                            //EventBus.$emit("logged-in", true, "", "");
                        }
                        self.error = err.response.data.message;
                    }
                });
        },
        loadUser() {
            let self = this;

            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
            };
            HTTP.get('getusers', config)
                .then(function (response) {
                    // console.log(response);
                    if (response.data.code == '200') {
                        self.userOptions= response.data.content;
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 0) {
                        self.error = "Remote server can not be reachable";
                    } else {
                        //redirect to login page if user not authenticated
                        if (err.response.status == 401) {
                            //clear previous data
                            localStorage.removeItem("user_obj");
                            localStorage.removeItem("api_token");
                            localStorage.removeItem("profile_image");
                            //EventBus.$emit("logged-in", true, "", "");
                            self.$router.push("/login");
                        }
                        self.error = err.response.data.message;
                    }
                })
        },
        loadLocations: function (callback) {
            let self = this;
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
            };
            HTTP.get('locations', config)
                .then(function (response) {
                    // console.log(response);
                    if (response.data.code == '200') {
                        self.locationOptions= response.data.content;
                        if(callback != null) {
                            callback();
                        }
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 0) {
                        self.error = "Remote server can not be reachable";
                    } else {
                        //redirect to login page if user not authenticated
                        if (err.response.status == 401) {
                            //clear previous data
                            localStorage.removeItem("user_obj");
                            localStorage.removeItem("api_token");
                            localStorage.removeItem("profile_image");
                            //EventBus.$emit("logged-in", true, "", "");
                            self.$router.push("/login");
                        }
                        self.error = err.response.data.message;
                    }
                });
        },
        checkIfSurveyAttempted: function(survey_id, callback) {

            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                params: {
                    survey_id: survey_id
                }
            };

            HTTP.get('survey/checkifattempted', config)
                .then(function(response) {
                    // If returns true notify User that this cannot be Edited
                    callback(response.data.content);
                })
                .catch(function(err) {
                    console.log(err);
                    this.catchHandler(err, function() {

                    });
                })
                .finally(function(){

                });
        },
        showSAlert(message, type, callback) {
            var result = this.$swal.fire(
                            message,
                            '',
                            type
                        );

                        this.$swal({
                            text: message,
                            type: type,
                            confirmButtonText: 'Ok',
                            showCloseButton: true,
                            showLoaderOnConfirm: false
                        }).then((result)=>{
                            if(result.value){
                                if(callback) {
                                    callback();
                                }
                            } else {
                                
                            }
                        });
            
        },
        genericResponseCodeHandler(response, callback) {
            switch (response.status) {
                case 204:
                    this.showSAlert(response.statusText, this.saOpTypes.INFO, callback);
                    break;
            }
        },
        catchHandler(err, callback) {
            if(typeof(err.response) != 'undefined') {
                switch (err.response.status) {
                    case 0:
                        this.showSAlert('Remote server is not reachable', this.saOpTypes.ERROR, callback);
                    case 400:
                        this.showSAlert(err.response.data.message, this.saOpTypes.ERROR, callback);
                        break;
                    case 401:
                        localStorage.removeItem("user_obj");
                        localStorage.removeItem("tkn");
                        this.$router.push("/login");
                        break;
                    case 404:
                        this.showSAlert(err.response.data.message, this.saOpTypes.ERROR, callback);
                        break;
                    case 409:
                        this.showSAlert(err.response.data.message, this.saOpTypes.ERROR, callback);
                        break;
                }
            } else {
                this.showSAlert(this.commonErrorMessages.ERROR1, this.saOpTypes.ERROR, callback);
            }
        },
        removeSpacesString(srting) {
            return srting.replace(/\s/g, '').toLowerCase();
        },
        stringTrim(string, length) {
            length = (typeof length !== 'undefined') ? length : 20;
            var string1 = (string.length > length) ? string.substring(0, length) + '...' : string;
            return string1;
        },
        showLoader() {
            $(".loading-container").show();
        },
        hideLoader() {
            //setTimeout(function () {
            $(".loading-container").hide();
            //}, 1700);
        },
        formatDate(source_date) {
            let date = new Date(source_date);
            return date.getFullYear() + '-' +
                (date.getMonth() +1) + '-' +
                date.getDate();
        },
        closeEvent: function () {
            console.log('close event called')
            this.close()
        },
        getRecords: function (serverPage, perPage, totalRecords) {
            serverPage = Number(serverPage);
            perPage = Number(perPage);
            totalRecords = Number(totalRecords);
            var firstLoad = true;
            var firstRecord = 0;
            var lastRecord = 0;

            var page = serverPage;
            for (var i = 1; i <= page; i++) {
                if (firstLoad == true) {
                    firstRecord = lastRecord + 1;
                    lastRecord = perPage;
                    firstLoad = false;
                } else {
                    firstRecord = lastRecord + 1;
                    lastRecord = (perPage * i);
                }
            }

            if (totalRecords != 0) {
                if (lastRecord > totalRecords) {
                    lastRecord = totalRecords;
                }
            } else if (totalRecords == 0) {
                firstRecord = 0;
                lastRecord = 0;
            }
            return firstRecord + " - " + lastRecord;
        },
        showLoader: function () {
            $(".loading-container").show();
        },
        hideLoader: function () {
            $(".loading-container").hide();
        },
        validEmail: function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
    },
    computed: {
        role: {
            get() {
                return this.$store.getters.role;
            },
            set(value) {
                this.$store.commit('changeUserRole', value);
            }
        },
    }
};