<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div class="page-name quiz-page-name">
                    {{ pageTitle }}
                    <button
                        id="exportAnsweredSurvey"
                        type="submit"
                        v-on:click.prevent="exportAnsweredQuestion"
                        class="btn btn-primary waves-effect pull-right"
                    >Export</button>
                </div>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <div class="col-12 forms-block custompadding0">
                    <div class="col-6 CustomFlaotLeft">
                        <div class="col-12 paddingleft0">
                            <div class="form-label">Select Survey :</div>
                        </div>
                        <div class="col-12 paddingleft0">
                            <v-select
                                v-model="selectedSurvey"
                                name="survey"
                                placeholder="Select Survey"
                                id="selectedSurvey"
                                @input="onChangeSurvey()"
                                :options="surveyOptions"
                                label="name"
                            >
                                <template slot="no-options">Type Survey Name here</template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">{{ option.name }}</div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">{{ option.name }}</div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-3 paddingleft0 QuestionCount">
                        <div class="form-label">Question Count : {{ selectedSurvey.questionCount }}</div>
                    </div>
                </div>
                <template>
                    <!-- <button type="submit" v-on:click.prevent="exportMultipleGivenAnswer" class="btn btn-primary waves-effect pull-right">Export</button> -->
                    <vue-good-table
                        ref="questiontable"
                        :columns="columns"
                        :rows="rows"
                        mode="remote"
                        :search-options="{
                            enabled: false
                        }"
                        @on-page-change="onPageChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        :totalRows="totalRecords"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            pageLabel: 'Page',
                            position: 'bottom',
                            perPage: serverParams.perPage,
                            perPageDropdown: [25, 50, 100, 200 ,500],
                            setCurrentPage: serverParams.setCurrentPage,
                            dropdownAllowAll: false,
                        }"
                    >
                        <!-- Custome Pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="vgt-wrap__footer crm">
                            <div  class="row">
                                <div class="col-sm-12 col-md-2 PerPage">
                                <v-select title="Select Per Page"
                                    v-model="serverParams.perPage"
                                    @change="changedValueTask"
                                    @input="changedValueTaskCustomPerPageTask()"
                                    name="customPerPage"
                                    id="customPerPage"
                                    label="text"
                                    :clearable="false"
                                    :searchable="false"
                                    :filterable="false"
                                    :options="serverParams.perPageDropdown"
                                    placeholder="Select Per Page">
                                </v-select>
                                </div>
                                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                <p class="pageRowCount">{{paginationDisplayRowsTask}} of {{totalRecords}}</p>
                                </div>
                                <div class="col-sm-12 col-md-6 Pagination">
                                <ul class="pagination">
                                    <li class="FirstPage" title="First Page">
                                    <template v-if="Number(serverParams.page) == 1">
                                        <a class="page-link" href="javascript:void(0)">1</a>
                                    </template>
                                    <template v-else>
                                        <a v-on:click="onPerticularPageClickTask(1)" class="page-link" href="javascript:void(0)">1</a>
                                    </template>
                                    </li>
                                    <li class="page-item PreviousPage" title="Previous Page">
                                    <template v-if="Number(serverParams.page) == 1">
                                        <a class="page-link" href="javascript:void(0)" tabindex="-1"><i class="fa fa-angle-left"></i></a>
                                    </template>
                                    <template v-else>
                                        <a class="page-link" href="javascript:void(0)" tabindex="-1" v-on:click="onPerticularPageClickTask(Number(serverParams.page)-1)" ><i class="fa fa-angle-left"></i></a>
                                    </template>
                                    </li>
                                    <template v-if="pageNumberListTask() < displayPageTask">
                                    <template v-for="(n, index) in pageNumberListTask()">
                                        <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                        <a class="page-link" href="javascript:void(0)">
                                            {{n}}
                                        </a>
                                        </li>
                                        <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                        <a class="page-link" href="javascript:void(0)">
                                            {{n}}
                                        </a>
                                        </li>
                                    </template>
                                    </template>
                                    <template v-else>
                                        <!--End New Implementation-->
                                        <template v-if="Number(serverParams.page) <= pageCompareTask">
                                        <template v-for="(n, index) in displayPageTask">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                        </template>
                                        </template>
                                        <template v-else-if="Number(serverParams.page) >= (pageNumberListTask() - (pageCompareTask-1))">
                                        <template v-for="(n, index) in pageNumberListTask()">
                                            <template v-if="n > (pageNumberListTask() - displayPageTask)">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                                </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                                </a>
                                            </li>
                                            </template>
                                        </template>
                                        </template>
                                        <template v-else>
                                        <template v-for="(n, index) in pageNumberListTask()" v-if="(n >= (Number(serverParams.page) - (pageCompareTask -1))) && (n <= (Number(serverParams.page) + (pageCompareTask -1)))">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                        </template>
                                        </template>
                                    </template>
                                    <li class="page-item NextPage" title="Next Page">
                                    <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                        <a class="page-link" href="javascript:void(0)"><i class="fa fa-angle-right"></i></a>
                                    </template>
                                    <template v-else>
                                        <a class="page-link" href="javascript:void(0)" v-on:click="onPerticularPageClickTask(Number(serverParams.page)+1)"><i class="fa fa-angle-right"></i></a>
                                    </template>
                                    </li>
                                    <li class="LastPage" title="Last Page">
                                    <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                        <a class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                    </template>
                                    <template v-else>
                                        <a v-on:click="onPerticularPageClickTask(pageNumberListTask())" class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                    </template>
                                    </li>
                                </ul>
                                </div>
                                <div class="mt-2 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                <p class="pageJumpLabel">Page : </p>
                                </div>
                                <div class="mt-1 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                <input @keyup.enter="jumpToPageInTask" v-model="jumpToPageTask" type="text" class="form-control form-control-sm" name="jumpToPageTask" id="jumpToPageTask" placeholder="Page">
                                </div>
                            </div>
                            </div>
                        </template>
                        <!-- End Custome Pagination -->
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'action'">
                                <b-button class="myButton" @click="exportGivenAnswer(props)">
                                    <i class="fa fa-file" style="font-size:24px;color:red"></i>
                                </b-button>
                                <b-button class="myButton" @click="deleteQuestion(props)">
                                    <i class="fa fa-trash-o" style="color:red"></i>
                                </b-button>
                            </span>
                            <span v-else>{{props.formattedRow[props.column.field]}}</span>
                        </template>
                    </vue-good-table>
                </template>
            </div>
            <!--page content-->
        </div>
        <backend-footer></backend-footer>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import { commonMixins } from "../../../mixins/commonMixins";
    import BackendFooter from "@/components/backend/partials/footer";
    import { VueGoodTable } from "vue-good-table";
    import "vue-good-table/dist/vue-good-table.css";
    import vSelect from "vue-select";
    import EventBus from "../../event-bus";
    Vue.use(VueGoodTable);
    var self;

    export default {
        name: "AnswerSurveyList",
        components: {
            "backend-footer": BackendFooter,
            "vue-good-table": VueGoodTable,
            "v-select": vSelect
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: "List Answer",
                firstLoad: 0,
                selectedSurvey: {
                    id: 0,
                    name: "",
                    questionCount: 0
                },
                selectedUser: {
                    dayforce_id: 0,
                    dayforce_id: ""
                },
                surveyOptions: [{}],
                userOptions: [{}],
                columns: [
                    /* {
                        label: "Dayforce Id",
                        field: "dayforce_id",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Dayforce Id", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Name",
                        field: "user_name",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Name", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Location",
                        field: "location",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Location", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Answered Question",
                        field: "answred_questions",
                        filterable: false,
                        filterOptions: {
                            enabled: false, // enable filter for this column
                            placeholder: "Id", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Score",
                        field: "score",
                        filterable: false,
                        filterOptions: {
                            enabled: false, // enable filter for this column
                            placeholder: "Id", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    }, */
                    {
                        label: "Question 1",
                        field: "answer_1",
                        sortable: false,
                        thClass: "disable-sorting"
                    },
                    {
                        label: "Question 2",
                        field: "answer_2",
                        sortable: false,
                        thClass: "disable-sorting"
                    },
                    {
                        label: "Question 3",
                        field: "answer_3",
                        sortable: false,
                        thClass: "disable-sorting"
                    },
                    {
                        label: "Question 4",
                        field: "answer_4",
                        sortable: false,
                        hidden: false,
                        thClass: "disable-sorting"
                    },
                    {
                        label: "Date",
                        field: "updated_at",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "MM/DD/YYYY", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Action",
                        field: "action",
                        sortable: false,
                        thClass: "disable-sorting"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {
                        question: "",
                        survey: ""
                    },
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    page: 1,
                    perPage: 25,
                    setCurrentPage: 1,
                    perPageDropdown : [25, 50, 100, 200, 500],
                },
                displayPageTask: 7,
                pageCompareTask: 0,
                paginationDisplayRowsTask: '',
                jumpToPageTask: 0,
            };
        },
        beforeMount: function() {
            self = this;
            EventBus.$emit("menu-selection-changed", "listansweredwurvey");
        },
        mounted: function() {
            this.jumpToPageTask = this.serverParams.page;
            this.pageCompareTask = Math.ceil(this.displayPageTask / 2);
            $("#jumpToPageTask").on('keyup', function (e) {
                if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
                    self.jumpToInputPageTask(self.jumpToPageTask)
                }
            });
            self.listSurvey();
        },
        methods: {
            changedValueTask: function (value) {
                /*console.log(value);*/
            },
            jumpToPageInTask: function (e) {
                self.jumpToInputPageTask(self.jumpToPageTask);
            },
            changedValueTaskCustomPerPageTask: function () {
                self.jumpToInputPageTask(self.serverParams.page);
                self.listSurvey();
            },
            pageNumberListTask: function () {
                var totalRecord = self.totalRecords;
                var count = 0;
                for (var i = 0; totalRecord > 0; i++) {
                    totalRecord = totalRecord - self.serverParams.perPage;
                    count++;
                }
                return count;
            },
            jumpToInputPageTask: function (pageNumber) {
                var reg = /^\d+$/;
                if (pageNumber <= 0 || pageNumber == '') {
                    pageNumber = 1;
                } else if (pageNumber > self.pageNumberListTask()) {
                    pageNumber = self.pageNumberListTask();
                    if (pageNumber <= 0 || pageNumber == '') {
                        pageNumber = 1;
                    }
                } else if (!String(self.jumpToPageTask).match(reg)) {
                    pageNumber = 1;
                }
                self.onPerticularPageClickTask(pageNumber);
            },
            onPerticularPageClickTask: function (pageNumber) {
                self.serverParams.page = pageNumber;
                self.jumpToPageTask = pageNumber;
                self.listSurvey();
            },
            listSurvey: function(config) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                HTTP.get("survey/list", config)
                    .then(function(response) {
                        if (
                            response.data.code == 200 &&
                            response.data.content.row.data.length > 0
                        ) {
                            self.surveyOptions = response.data.content.row.data;
                            if(self.selectedSurvey.id == 0) {
                                self.selectedSurvey.id = self.surveyOptions[0].id;
                                self.selectedSurvey.name = self.surveyOptions[0].name;
                                self.selectedSurvey.questionCount = self.surveyOptions[0].question_count;
                            }
                            // self.listAnswer(self.surveyOptions[0].id);
                            self.listAnswer(self.selectedSurvey.id);
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            listAnswer: function(survey_id) {
                self.firstLoad = 1;
                self.serverParams.setCurrentPage = self.serverParams.page;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: survey_id,
                        page: self.serverParams.page,
                        perPage: self.serverParams.perPage,
                        filters: self.serverParams.columnFilters,
                        sort: self.serverParams.sort
                    }
                };
                self.showLoader();
                HTTP.get("survey/answer/list", config)
                    .then(function(response) {
                        self.hideLoader();
                        //console.log(response.data.content);
                        if (response.data.code == 200) {
                            self.rows = response.data.content.row;
                            // self.totalRecords = response.data.content.total_records;
                            self.totalRecords = response.data.content.totalRecords;
                            if(response.data.questionArr && response.data.questionArr.length > 0) {
                                for(var i=0; i < response.data.questionArr.length; i++) {
                                    if(i == 4) {
                                        break;
                                    }
                                    self.columns[i].label = response.data.questionArr[i];
                                }
                                if(response.data.questionArr.length < 4) {
                                    for(var i = 0; i < self.columns.length;i++){
									    if (self.columns[i].field == "answer_4") {
                                            self.$set(self.columns[i], 'hidden', true);
                                        }
                                    }
                                }
                            } else {
                               for(var i=0; i < 4; i++) {
                                   self.columns[i].label = 'Question ' + (i+1).toString();
                               } 
                            }
                        }
                        self.paginationDisplayRowsTask = self.getRecords(self.serverParams.page, self.serverParams.perPage, self.totalRecords);
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            exportAnsweredQuestion: function() {
                $('#exportAnsweredSurvey').text('Exporting...');
                $('#exportAnsweredSurvey').prop("disabled", true);
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: self.selectedSurvey.id,
                        // page: self.serverParams.page,
                        // perPage: self.serverParams.perPage,
                        filters: self.serverParams.columnFilters,
                        sort: self.serverParams.sort,
                        name: self.selectedSurvey.name,
                        questionCount: self.selectedSurvey.questionCount
                    }
                };
                self.showLoader();
                HTTP.get("survey/answer/user/list/export", config)
                    .then(function(response) {
                        self.hideLoader();
                        $('#exportAnsweredSurvey').text('Export');
                        $('#exportAnsweredSurvey').prop("disabled", false);
                        if (response.data.code == 200) {
                            location.href = response.data.content;
                            /*self.rows = response.data.content.row;
                                            self.totalRecords = response.data.content.total_records;*/
                        } else {
                            self.genericResponseCodeHandler(response, function() {});
                        }
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        $('#exportAnsweredSurvey').text('Export');
                        $('#exportAnsweredSurvey').prop("disabled", false);
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            exportMultipleGivenAnswer: function() {
                var idArray = [];
                self.$refs["questiontable"].selectedRows.forEach(function(row) {
                    idArray.push(row.id);
                });
                if (idArray.length == 0) {
                    alert("Select at-least one row");
                    return;
                }
                console.log(idArray);
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: self.selectedSurvey.id,
                        user_survey_mapper_id: idArray
                    }
                };
                HTTP.post("survey/answer/multiple/list/export", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            //location.href = response.data.content.web;
                            /*self.rows = response.data.content.row;
                                            self.totalRecords = response.data.content.total_records;*/
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            deleteQuestion: function(params) {
                self.$swal({
                        title: "Are you sure?",
                        text: "You can't revert your action",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes Delete it!",
                        cancelButtonText: "No, Keep it!",
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    })
                    .then(willDelete => {
                        if (willDelete.value) {
                            var dayforce_id = params.row.dayforce_id;
                            var user_survey_mapper_id = params.row.id;
                            console.log(dayforce_id);
                            var config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("api_token")
                                }
                            };
                            var data = {
                                survey_id: self.selectedSurvey.id,
                                user_survey_mapper_id: user_survey_mapper_id,
                                score: params.row.score,
                                dayforce_id: dayforce_id
                            };
                            var apiUrl = "survey/answer/list/delete";

                            HTTP.post(apiUrl, data, config)
                                .then(function(response) {
                                    if (response.data.code == 200) {
                                    //location.href = response.data.content.web;
                                    self.listSurvey();
                                    self.$swal(
                                        "Deleted",
                                        "You successfully deleted the record.",
                                        "success"
                                    );
                                    /*self.rows = response.data.content.row;
                                                            self.totalRecords = response.data.content.total_records;*/
                                    }
                                })
                                .catch(function(err) {
                                    console.log(err);
                                    self.catchHandler(err, function() {});
                            });
                        } else {
                            self.$swal("Cancelled", "Your record still intact", "info");
                        }
                    });
                return;
            },
            exportGivenAnswer: function(params) {
                var dayforce_id = params.row.dayforce_id;
                var user_survey_mapper_id = params.row.id;
                console.log(dayforce_id);
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: self.selectedSurvey.id,
                        user_survey_mapper_id: user_survey_mapper_id,
                        score: params.row.score,
                        dayforce_id: dayforce_id
                    }
                };

                HTTP.get("survey/answer/list/export", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            location.href = response.data.content.web;
                            /*self.rows = response.data.content.row;
                                            self.totalRecords = response.data.content.total_records;*/
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            onPageChange(params) {
                self.updateParams({ page: params.currentPage });
                self.listAnswer(self.selectedSurvey.id);
            },
            updateParams(newProps) {
                self.serverParams = Object.assign({}, self.serverParams, newProps);
            },
            onPerPageChange(params) {
                self.updateParams({ perPage: params.currentPerPage });
                self.totalRecords = self.totalRecords;
                self.listAnswer(self.selectedSurvey.id);
            },
            onColumnFilter(params) {
                self.isLoading = false;
                self.updateParams(params);
                {
                    field: "question";
                    field: "survey";
                }

                this.serverParams.page = 1;
                var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
                var filters = filtersPr.columnFilters;

                if (self.firstLoad == 1 || filters.question || filters.section) {
                    this.listAnswer(self.selectedSurvey.id);
                }
            },
            onSortChange(params) {
                //console.log(params);
                this.updateParams({
                    sort: {
                        type: params[0].type,
                        field: params[0].field
                    }
                });
                this.listAnswer(self.selectedSurvey.id);
            },
            onRowClick(params) {
                var id = params.row.id;
                self.$router.push({ path: `/backend/editquestion/${id}` });
            },
            selectionChanged() {
                //var self = this;
                //console.log(this.$refs['questiontable'].selectedRows);
            },
            listUsers(survey_id) {
                var self = this;
                var param = {
                    survey_id: survey_id
                };

                HTTP.post("survey/answer/userlist", param)
                    .then(function(response) {
                        //console.log(response.data);
                        if (response.data.code == 200) {
                            self.userOptions = response.data.content;
                            //self.totalRecords = response.data.content.total_records;
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            onChangeSurvey: function() {
                var survey_id = self.selectedSurvey.id;
                //console.log(self.selectedSurvey);
                self.selectedSurvey.questionCount = self.selectedSurvey.question_count;
                //self.listUsers(survey_id);
                self.listAnswer(survey_id);
            }
        }
    };
</script>

<style>
</style>
