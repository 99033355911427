<template>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="message-info" v-show="!isEditingAllowed">
                <div class="message-info-block">Editing is disabled for this Survey as it is already Attempted by User(s).</div>
            </div>
            <div class="page-header-top-container">
                <div class="page-name">{{ pageTitle }}</div>
                    <button type="submit" v-on:click.prevent="addQuestion" class="btn btn-primary waves-effect pull-right" :disabled="!isEditingAllowed">Add</button>
                    <button id='exportQuestions' type="submit" v-on:click.prevent="exportQuestion" class="btn btn-primary waves-effect pull-right">Export</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <div class="col-12 forms-block SelectQuizBlock">
                        <div class="col-5 paddingleft0">
                            <div class="form-label">Select Survey :</div>
                        </div>
                        <div class="col-5 paddingleft0">
                            <v-select
                                v-model="selectedSurvey"
                                name="survey"
                                placeholder="Select Survey"
                                id="selectedSurvey"
                                @input="onChangeSurvey()"
                                :options="surveyOptions"
                                :filter-by="surveyFilter"
                                label="unique_code"
                            >
                                <template slot="no-options">Type Survey here</template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">{{ option.name }} {{ option.unique_code }}</div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">{{ option.name }} {{ option.unique_code }}</div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <template>
                        <vue-good-table
                            ref="questiontable"
                            :columns="columns"
                            :rows="rows"
                            mode="remote"
                            :search-options="{
                                enabled: false
                            }"
                            @on-page-change="onPageChange"
                            @on-selected-rows-change="selectionChanged"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            :totalRows="totalRecords"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                pageLabel: 'Page',
                                position: 'bottom',
                                perPage: serverParams.perPage,
                                perPageDropdown: [25, 50, 100, 200 ,500],
                                dropdownAllowAll: false,
                                setCurrentPage: serverParams.setCurrentPage,
                            }"
                        >
                            <!-- Custome Pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="vgt-wrap__footer crm">
                                <div  class="row">
                                    <div class="col-sm-12 col-md-2 PerPage">
                                    <v-select title="Select Per Page"
                                        v-model="serverParams.perPage"
                                        @change="changedValueTask"
                                        @input="changedValueTaskCustomPerPageTask()"
                                        name="customPerPage"
                                        id="customPerPage"
                                        label="text"
                                        :clearable="false"
                                        :searchable="false"
                                        :filterable="false"
                                        :options="serverParams.perPageDropdown"
                                        placeholder="Select Per Page">
                                    </v-select>
                                    </div>
                                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                                    <p class="pageRowCount">{{paginationDisplayRowsTask}} of {{totalRecords}}</p>
                                    </div>
                                    <div class="col-sm-12 col-md-6 Pagination">
                                    <ul class="pagination">
                                        <li class="FirstPage" title="First Page">
                                        <template v-if="Number(serverParams.page) == 1">
                                            <a class="page-link" href="javascript:void(0)">1</a>
                                        </template>
                                        <template v-else>
                                            <a v-on:click="onPerticularPageClickTask(1)" class="page-link" href="javascript:void(0)">1</a>
                                        </template>
                                        </li>
                                        <li class="page-item PreviousPage" title="Previous Page">
                                        <template v-if="Number(serverParams.page) == 1">
                                            <a class="page-link" href="javascript:void(0)" tabindex="-1"><i class="fa fa-angle-left"></i></a>
                                        </template>
                                        <template v-else>
                                            <a class="page-link" href="javascript:void(0)" tabindex="-1" v-on:click="onPerticularPageClickTask(Number(serverParams.page)-1)" ><i class="fa fa-angle-left"></i></a>
                                        </template>
                                        </li>
                                        <template v-if="pageNumberListTask() < displayPageTask">
                                        <template v-for="(n, index) in pageNumberListTask()">
                                            <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                            <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                            <a class="page-link" href="javascript:void(0)">
                                                {{n}}
                                            </a>
                                            </li>
                                        </template>
                                        </template>
                                        <template v-else>
                                            <!--End New Implementation-->
                                            <template v-if="Number(serverParams.page) <= pageCompareTask">
                                            <template v-for="(n, index) in displayPageTask">
                                                <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                <a class="page-link" href="javascript:void(0)">
                                                    {{n}}
                                                </a>
                                                </li>
                                                <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                <a class="page-link" href="javascript:void(0)">
                                                    {{n}}
                                                </a>
                                                </li>
                                            </template>
                                            </template>
                                            <template v-else-if="Number(serverParams.page) >= (pageNumberListTask() - (pageCompareTask-1))">
                                            <template v-for="(n, index) in pageNumberListTask()">
                                                <template v-if="n > (pageNumberListTask() - displayPageTask)">
                                                <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                    <a class="page-link" href="javascript:void(0)">
                                                    {{n}}
                                                    </a>
                                                </li>
                                                <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                    <a class="page-link" href="javascript:void(0)">
                                                    {{n}}
                                                    </a>
                                                </li>
                                                </template>
                                            </template>
                                            </template>
                                            <template v-else>
                                            <template v-for="(n, index) in pageNumberListTask()" v-if="(n >= (Number(serverParams.page) - (pageCompareTask -1))) && (n <= (Number(serverParams.page) + (pageCompareTask -1)))">
                                                <li :title="'Page ' +n" class="PagesClass page-item active" v-bind:id="'pageNumber' + n" v-if="n == Number(serverParams.page)">
                                                <a class="page-link" href="javascript:void(0)">
                                                    {{n}}
                                                </a>
                                                </li>
                                                <li :title="'Page ' +n" v-on:click="onPerticularPageClickTask(n)" class="PagesClass page-item" v-bind:id="'pageNumber' + n" v-else>
                                                <a class="page-link" href="javascript:void(0)">
                                                    {{n}}
                                                </a>
                                                </li>
                                            </template>
                                            </template>
                                        </template>
                                        <li class="page-item NextPage" title="Next Page">
                                        <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                            <a class="page-link" href="javascript:void(0)"><i class="fa fa-angle-right"></i></a>
                                        </template>
                                        <template v-else>
                                            <a class="page-link" href="javascript:void(0)" v-on:click="onPerticularPageClickTask(Number(serverParams.page)+1)"><i class="fa fa-angle-right"></i></a>
                                        </template>
                                        </li>
                                        <li class="LastPage" title="Last Page">
                                        <template v-if="Number(serverParams.page) == pageNumberListTask()">
                                            <a class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                        </template>
                                        <template v-else>
                                            <a v-on:click="onPerticularPageClickTask(pageNumberListTask())" class="page-link" href="javascript:void(0)" tabindex="-1">{{pageNumberListTask()}}</a>
                                        </template>
                                        </li>
                                    </ul>
                                    </div>
                                    <div class="mt-2 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <p class="pageJumpLabel">Page : </p>
                                    </div>
                                    <div class="mt-1 col-lg-1 col-md-1 col-sm-2 col-xs-6">
                                    <input @keyup.enter="jumpToPageInTask" v-model="jumpToPageTask" type="text" class="form-control form-control-sm" name="jumpToPageTask" id="jumpToPageTask" placeholder="Page">
                                    </div>
                                </div>
                                </div>
                            </template>
                            <!-- End Custome Pagination -->
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'action'" v-show="isEditingAllowed">
                                    <b-button class="myButton" @click="editQuestion(props)">
                                        <i class="fa fa-edit"></i>
                                    </b-button>
                                    <b-button class="myButton" @click="deleteQuestion(props)">
                                        <i class="fa fa-trash-o" style="color:red"></i>
                                    </b-button>
                                </span>
                                <span v-else>{{props.formattedRow[props.column.field]}}</span>
                            </template>
                        </vue-good-table>
                    </template>
                </div>
                <!--page content-->
            </div>
            <backend-footer></backend-footer>
        </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import { commonMixins } from "../../../mixins/commonMixins";
    import BackendFooter from "@/components/backend/partials/footer";
    import { VueGoodTable } from "vue-good-table";
    import "vue-good-table/dist/vue-good-table.css";
    import vSelect from "vue-select";
    import EventBus from "../../event-bus";
    Vue.use(VueGoodTable);
    var self;

    export default {
        name: "QuestionList",
        components: {
            "backend-footer": BackendFooter,
            "vue-good-table": VueGoodTable,
            "v-select": vSelect
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: "Question List",
                firstLoad: 0,
                selectedSurvey: {
                    id: 0,
                    name: ""
                },
                surveyOptions: [{}],
                columns: [
                    {
                        label: "Position",
                        field: "position",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Position", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Question",
                        field: "question",
                        filterable: true,
                        filterOptions: {
                            enabled: true, // enable filter for this column
                            placeholder: "Question", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Is Mandatory",
                        field: "is_required",
                        filterable: false,
                        sortable: false,
                        filterOptions: {
                            enabled: false, // enable filter for this column
                            placeholder: "Unique Code", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Answers",
                        field: "answer_count",
                        filterable: false,
                        sortable: true,
                        filterOptions: {
                            enabled: false, // enable filter for this column
                            placeholder: "Answer Count", // placeholder for filter input
                            filterValue: "", // initial populated value for this filter
                            trigger: "enter" //only trigger on enter not on keyup
                        }
                    },
                    {
                        label: "Action",
                        field: "action",
                        sortable: false,
                        thClass: "disable-sorting"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {
                        question: "",
                        survey: "",
                        position : ""
                    },
                    sort: {
                        field: "id",
                        type: "asc"
                    },
                    page: 1,
                    perPage: 25,
                    setCurrentPage: 1,
                    perPageDropdown : [25, 50, 100, 200, 500],
                },
                isEditingAllowed: true,
                displayPageTask: 7,
                pageCompareTask: 0,
                paginationDisplayRowsTask: '',
                jumpToPageTask: 0,
            };
        },
        beforeMount: function() {
            self = this;
            EventBus.$emit("menu-selection-changed", "listquestion");
        },
        mounted: function() {
            this.jumpToPageTask = this.serverParams.page;
            this.pageCompareTask = Math.ceil(this.displayPageTask / 2);
            $("#jumpToPageTask").on('keyup', function (e) {
                if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
                    self.jumpToInputPageTask(self.jumpToPageTask)
                }
            });
            let listSurveyLocalStorage = JSON.parse(localStorage.getItem('listQuestionSelectedSurvey'));
            if (listSurveyLocalStorage != null && listSurveyLocalStorage !== "" && typeof listSurveyLocalStorage !== 'undefined') {
                self.selectedSurvey = {id : listSurveyLocalStorage.id, name : listSurveyLocalStorage.name};
            }
            self.listSurvey();
            // self.getLastPositionForSurveyIdQuestion();
        },
        methods: {
            changedValueTask: function (value) {
                /*console.log(value);*/
            },
            jumpToPageInTask: function (e) {
                self.jumpToInputPageTask(self.jumpToPageTask);
            },
            changedValueTaskCustomPerPageTask: function () {
                self.jumpToInputPageTask(self.serverParams.page);
                self.listSurvey();
            },
            pageNumberListTask: function () {
                var totalRecord = self.totalRecords;
                var count = 0;
                for (var i = 0; totalRecord > 0; i++) {
                    totalRecord = totalRecord - self.serverParams.perPage;
                    count++;
                }
                return count;
            },
            jumpToInputPageTask: function (pageNumber) {
                var reg = /^\d+$/;
                if (pageNumber <= 0 || pageNumber == '') {
                    pageNumber = 1;
                } else if (pageNumber > self.pageNumberListTask()) {
                    pageNumber = self.pageNumberListTask();
                    if (pageNumber <= 0 || pageNumber == '') {
                        pageNumber = 1;
                    }
                } else if (!String(self.jumpToPageTask).match(reg)) {
                    pageNumber = 1;
                }
                self.onPerticularPageClickTask(pageNumber);
            },
            onPerticularPageClickTask: function (pageNumber) {
                self.serverParams.page = pageNumber;
                self.jumpToPageTask = pageNumber;
                self.listSurvey();
            },
            listSurvey: function(config) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                HTTP.get("survey/list", config)
                    .then(function(response) {
                        if (
                            response.data.code == 200 &&
                            response.data.content.row.data.length > 0
                        ) {
                            self.surveyOptions = response.data.content.row.data;
                            if(self.selectedSurvey.id == 0) {
                                self.selectedSurvey.id = self.surveyOptions[0].id;
                                self.selectedSurvey.name = self.surveyOptions[0].name;
                            }

                            self.checkIfSurveyAttempted(self.selectedSurvey.id, function(result) {
                                if (result) {
                                    self.isEditingAllowed = false;
                                } else {
                                    self.isEditingAllowed = true;
                                }
                                self.listQuestion(self.selectedSurvey.id);
                            });
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {});
                    });
            },
            addQuestion: function() {
                self.$router.push("/backend/addquestion/" + self.selectedSurvey.id);
            },
            changeToDraggableView : function() {
                self.$router.push({name : 'DraggableQuestionList'});
            },
            editQuestion(params) {
                var id = params.row.id;
                self.$router.push({ path: `/backend/editquestion/${id}` });
            },
            deleteQuestion(params) {
                var id = params.row.id;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };

                HTTP.delete("question/delete", config)
                    .then(function(response) {
                        if (response.data.code == 200) {
                            self.listSurvey();
                            self.showSAlert(
                                response.data.content.message,
                                self.saOpTypes.SUCCESS,
                                function() {}
                            );
                        }
                    })
                    .catch(function(err) {
                        self.catchHandler(err, function() {});
                    });
            },
            listQuestion: function(survey_id) {
                self.firstLoad = 1;
                self.serverParams.setCurrentPage = self.serverParams.page;
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: survey_id,
                        page: self.serverParams.page,
                        perPage: self.serverParams.perPage,
                        filters: self.serverParams.columnFilters,
                        sort: self.serverParams.sort
                    }
                };
                self.showLoader();
                HTTP.get("question/list", config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            self.rows = response.data.content.row.data;
                            self.totalRecords = response.data.content.total_records;
                        }
                        self.paginationDisplayRowsTask = self.getRecords(self.serverParams.page, self.serverParams.perPage, self.totalRecords);
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        self.catchHandler(err, function() {});
                    });
                },
            exportQuestion: function() {
                $('#exportQuestions').text('Exporting...');
                $('#exportQuestions').prop("disabled", true);
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        survey_id: self.selectedSurvey.id,
                        page: self.serverParams.page,
                        perPage: self.serverParams.perPage,
                        filters: self.serverParams.columnFilters,
                        sort: self.serverParams.sort
                    }
                };
                self.showLoader();
                HTTP.get("question/export", config)
                    .then(function(response) {
                        $('#exportQuestions').text('Export');
                        $('#exportQuestions').prop("disabled", false);
                        if (response.data.code == 200) {
                            // location.href = response.data.content.web;
                            location.href = response.data.content;
                            self.rows = response.data.content.row;
                            self.totalRecords = response.data.content.total_records;
                        }
                        self.hideLoader();
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        $('#exportQuestions').text('Export');
                        $('#exportQuestions').prop("disabled", false);
                        self.catchHandler(err, function() {});
                    });
            },
            onPageChange(params) {
                self.updateParams({ page: params.currentPage });
                self.listQuestion(self.selectedSurvey.id);
            },
            updateParams(newProps) {
                self.serverParams = Object.assign({}, self.serverParams, newProps);
            },
            onPerPageChange(params) {
                self.updateParams({ perPage: params.currentPerPage });
                self.totalRecords = self.totalRecords;
                self.listQuestion(self.selectedSurvey.id);
            },
            onColumnFilter(params) {
                self.isLoading = false;
                self.updateParams(params);
                {
                    field: "question";
                    field: "survey";
                    field: "position";
                }

                this.serverParams.page = 1;
                var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
                var filters = filtersPr.columnFilters;

                if (self.firstLoad == 1 || filters.question || filters.section) {
                    this.listQuestion(self.selectedSurvey.id);
                }
            },
            onSortChange(params) {
            //console.log(params);
                this.updateParams({
                    sort: {
                        type: params[0].type,
                        field: params[0].field
                    }
                });
                this.listQuestion(self.selectedSurvey.id);
            },
            onRowClick(params) {
                var id = params.row.id;
                self.$router.push({ path: `/backend/editquestion/${id}` });
            },
            selectionChanged() {
                var self = this;
            },
            onChangeSurvey: function() {
                if (self.selectedSurvey != null) {
                    let surveyId = self.selectedSurvey.id;
                    let surveyName = self.selectedSurvey.name;
                    let surveyObj = {id : surveyId, name : surveyName};
                    localStorage.setItem("listQuestionSelectedSurvey", JSON.stringify(surveyObj));
                    var survey_id = self.selectedSurvey.id;
                    self.checkIfSurveyAttempted(survey_id, function(result) {
                        if (result) {
                            self.isEditingAllowed = false;
                        } else {
                            self.isEditingAllowed = true;
                        }
                        self.listQuestion(survey_id);
                    });
                }
            },
            surveyFilter: function(option, label, search) {
                let temp = search.toLowerCase();
                return (
                    option.name.toLowerCase().indexOf(temp) > -1 ||
                    option.unique_code.toLowerCase().indexOf(temp) > -1
                );
            }
        }
    };
</script>

<style>
</style>
