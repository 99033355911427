<template>
    <!--<h1>{{ msg }}</h1>-->
    <section class="welcomebg">
        <div class="welcomecontent">
            <div class="welcomelogo">
                <img src="/static/images/vtc-logo.png" alt="Velocity Truck Center">
            </div>
            <div class="welcomequizcontainer">
                <div class="welcomequizurl">
                    <label>Enter Survey URL</label>
                    <input type="text" name="quizCode" @keyup.enter="checkSurvey" v-model="surveyCode">
                    <div class="enterquizbutton">
                        <a href="javascript:void(0)" @click.prevent="checkSurvey" :class="disabledClass">Enter Survey</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    var self;
    import {HTTP} from '../http-common';
    import {commonMixins} from '../mixins/commonMixins';

    export default {
        name: 'Welcome',
        data () {
            return {
                msg: 'Welcome to Your Vue.js App',
                surveyCode: "",
                surveyUrl: "",
                disabledcheckSurveyClass: true,
            }
        },
        mixins: [commonMixins],
        beforeMount: function() {
            self = this;
        },
        mounted: function() {
            self.surveyUrl = process.env.VUE_APP_FRONTEND;
        },
        watch: {
            surveyCode: function(val) {
                if(val.length > 0) {
                    self.disabledcheckSurveyClass = false;
                } else {
                    self.disabledcheckSurveyClass = true;
                }
            }
        },
        methods: {
            checkSurvey: function () {
                //surveyUrl+surveyCode
                if(self.surveyCode.length == 0) {
                    return;
                }
                var config = {
                    params: {
                        unique_code: self.surveyCode
                    }
                };
                HTTP.get('survey/checkifexists', config)
                    .then(function(response) {
                        if(response.data.content) {
                            location.href = self.surveyUrl + self.surveyCode
                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.catchHandler(err, function() {
                        });
                    })
                    .finally(function() {
                    });
            }
        },
        computed: {
            disabledClass: () => {
                return self.disabledcheckSurveyClass ? "disabled" : ""
            }
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .disabled {
        pointer-events: none;
        color: gray;
    }
    h1, h2 {
        font-weight: normal;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
