import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from '@/components/HelloWorld';
import Welcome from '@/components/Welcome';
import Login from '@/components/backend/Login';
import Survey from '@/components/Survey';
import ListSurvey from '@/components/backend/Survey/ListSurvey';
import AddEditSurvey from '@/components/backend/Survey/AddEditSurvey';
import ListAnsweredSurvey from '@/components/backend/Survey/ListAnsweredSurvey';
import ListQuestion from '@/components/backend/question/ListQuestion';
import DraggableQuestionList from '@/components/backend/question/DraggableQuestionList';
import AddEditQuestion from '@/components/backend/question/AddEditQuestion';
import ListUser from '@/components/backend/UserManagement/ListUser';
import AddUpdateUser from '@/components/backend/UserManagement/AddUpdateUser';

import ListLogo from '@/components/backend/Logo/ListLogo';
import AddUpdateLogo from '@/components/backend/Logo/AddUpdateLogo';

import SurveySuccess from '@/components/SurveySuccess';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/',
            name: 'Welcome',
            component: Welcome
        },
        {
            path: '/survey/submit',
            name: 'SurveySuccess',
            component: SurveySuccess
        },
        {
            path: '/survey/:url',
            name: 'Survey',
            component: Survey
        },
        {
            path: '/backend/listansweredsurvey',
            name: 'ListAnsweredSurvey',
            component: ListAnsweredSurvey
        },
        {
            path: '/backend/listsurvey',
            name: 'ListSurvey',
            component: ListSurvey
        },
        {
            path: '/backend/addsurvey',
            name: 'AddSurvey',
            component: AddEditSurvey
        },
        {
            path: '/backend/editsurvey/:id',
            name: 'EditSurvey',
            component: AddEditSurvey
        },
        {
            path: '/backend/draggablequestionlist',
            name: 'DraggableQuestionList',
            component: DraggableQuestionList
        },
        {
            path: '/backend/listquestion',
            name: 'ListQuestion',
            component: ListQuestion
        },
        {
            path: '/backend/addquestion/:survey_id',
            name: 'AddQuestion',
            component: AddEditQuestion
        },
        {
            path: '/backend/editquestion/:id',
            name: 'EditQuestion',
            component: AddEditQuestion
        },
        {
            path: '/backend/user/list',
            name: 'ListUser',
            component: ListUser
        },
        {
            path: '/backend/user/add',
            name: 'AddUpdateUser',
            component: AddUpdateUser
        },
        {
            path: '/backend/user/edit/:id',
            name: 'UpdateUser',
            component: AddUpdateUser
        },
        {
            path: '/backend/logo/add',
            name: 'AddLogo',
            component: AddUpdateLogo
        },
        {
            path: '/backend/logo/edit/:id',
            name: 'UpdateLogo',
            component: AddUpdateLogo
        },
        {
            path: '/backend/logo/list',
            name: 'ListLogo',
            component: ListLogo
        },
    ]
})
